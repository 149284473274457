import React, { useState } from "react";
import styles from "./index.module.scss";
import Input from "components/common/Input";
const Visitors = prop => {
  const [nickname, setnickname] = useState("");
  const [isWrite, setIsWrite] = useState(false);
  const getQueryString = name => {
    const reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
    const r = window.location.search.substr(1).match(reg);
    if (r != null) return unescape(r[2]);
    return null;
  };
  const send = () => {
    if (!nickname) {
      setIsWrite(true);
      return;
    }
    const bookId = getQueryString("bookId");
    const roomId = getQueryString("roomId");
    window.open(
      `https://reader-onetutor-dev.oneclass.com.tw/${bookId}?roomId=${roomId}&spectators=${nickname}`
    );
  };

  return (
    <div className={styles.visitors}>
      <div className={styles.title}>歡迎來到萬課室的教室沙龍</div>
      <div className={styles.title}>請先輸入暱稱</div>
      <Input
        value={nickname}
        placeHolder="請輸入您的暱稱"
        onChange={e => {
          setnickname(e.target.value);
        }}
      />
      {isWrite ? <span className={styles.note}>您尚未輸入暱稱</span> : ""}
      <div className={styles.title}>接著按下送出，就可以開始與老師互動囉!!</div>
      <div className={styles.send} onClick={send}>
        送出
      </div>
    </div>
  );
};

export default Visitors;
