import React from "react";
import classnames from "classnames";
import styles from "./index.module.scss";

const Select = ({
  defaultText = "請選擇",
  value,
  className,
  disabled,
  options,
  label,
  onChange,
  onKeyDown = () => {},
  ...other
}) => {
  return (
    <div className={classnames(styles.selectWrapper, className)}>
      <label>
        {label && <div> {label} </div>}
        <select onChange={onChange} disabled={disabled}>
          <option value="" selected={value === ""} disabled>
            {defaultText}
          </option>
          {options.length > 0 &&
            options.map((item, index) => {
              return (
                <option
                  key={item.Text + index}
                  selected={value === item.Value}
                  value={item.Value}
                >
                  {item.Text}
                </option>
              );
            })}
        </select>
      </label>
    </div>
  );
};

export default Select;
