import Url from "url";
import { logoutNaniOneClass } from "static/js/login";

const Success = 'success';

export const PreparationAPI = {
  get: async (url, headers, params) => {
    const parsed = Url.parse(url);
    try {
      const response = await fetch(
        Url.format({
          ...parsed,
          query: params
        }),
        {
          method: "GET",
          headers: {
            ...headers
          }
        }
      ).then(res=>res.json());
      if(response.status === Success || response.isSuccess || response.code === "SUCCESS"){
        return response;
      }else{
        if(typeof(response.error.errorCode) === 'object' || response.error.errorCode === '1001'){
          throw response;
        }else{
          return response;
        }
      }
    } catch (err) {
      logoutNaniOneClass();
      alert('token 異常');
      window.location.replace('/');
      return console.error(err);
    }
  },
  post: async (url, headers, params = {}) => {
    const body = JSON.stringify(params);
    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          ...headers
        },
        body
      }).then(res=>res.json());
      if(response.status === Success || response.isSuccess || response.code === "SUCCESS"){
        return response;
      }else{
        if(typeof(response.error.errorCode) === 'object' || response.error.errorCode === '1001'){
          throw response;
        }else{
          return response;
        }
      }
    } catch (res) {
      logoutNaniOneClass();
      alert('token 異常');
      window.location.replace('/');
      return res;
    }
  },
  put: async (url, headers, params = {}) => {
    const body = JSON.stringify(params);
    try {
      const response = await fetch(url, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          ...headers
        },
        body
      }).then(res=>res.json());
      if(response.status === Success || response.isSuccess || response.code === "SUCCESS"){
        return response;
      }else{
        if(typeof(response.error.errorCode) === 'object' || response.error.errorCode === '1001'){
          throw response;
        }else{
          return response;
        }
      }
    } catch (err) {
        logoutNaniOneClass();
        alert('token 異常');
        window.location.replace('/');
      return console.error(err);
    }
  },
  delete: async (url, headers) => {
    try {
      const response = await fetch(url, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          ...headers
        }
      }).then(res=>res.json());
      if(response.status === Success || response.isSuccess || response.code === "SUCCESS"){
        return response;
      }else{
        if(typeof(response.error.errorCode) === 'object' || response.error.errorCode === '1001'){
          throw response;
        }else{
          return response;
        }
      }
    } catch (err) {
      logoutNaniOneClass();
      alert('token 異常');
      window.location.replace('/');
      return console.error(err);
    }
  },
  formPost: async (url, headers, params) => {
    const searchParams = Object.keys(params)
      .map(key => {
        return encodeURIComponent(key) + "=" + encodeURIComponent(params[key]);
      })
      .join("&");
    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          ...headers
        },
        body: searchParams
      }).then(res=>res.json());
      if(response.status === Success || response.isSuccess){
        return response;
      }else{
        if(typeof(response.error.errorCode) === 'object' || response.error.errorCode === '1001'){
          throw response;
        }else{
          return response;
        }
      }
    } catch (err) {
        logoutNaniOneClass();
        alert('token 異常');
        window.location.replace('/');
      return console.log(err);
    }
  },
  postText: async (url, params = {}) => {
    const body = JSON.stringify(params);
    try {
      const response = await fetch(url, {
        method: "POST",
        body
      });
      return await response.text();
    } catch (err) {
      return console.log(err);
    }
  },
  clubPost: async (url, headers, params = {}) => {
    const body = JSON.stringify(params);
    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          ...headers
        },
        body
      }).then(res=>res.json());
      if(response.code === "SUCCESS" || response.message){
        return response;
      }else{
        if(typeof(response.error.errorCode) === 'object' || response.error.errorCode === '1001'){
          throw response;
        }else{
          return response;
        }
      }
    } catch (res) {
      return res;
    }
  },
};
