import React from "react";
import classnames from "classnames";
import styles from "./index.module.scss";
import Icon from "components/common/Icon";
const IconInput = ({
  type = "text",
  IconName,
  className,
  value = "",
  label,
  onChange,
  onKeyDown = () => {},
  maxLength,
  errorMessage,
  ...other
}) => (
  <div className={classnames(styles.inputWrapper, className)}>
    <label>
      {label && <div> {label} </div>}
      <div className={styles.inputIconBox}>
        {IconName && (
          <div className={styles.inputIcon}>
            <Icon name={IconName} />
          </div>
        )}
        <input
          type={type}
          value={value}
          onKeyDown={e => {
            onKeyDown(e);
            e.stopPropagation();
          }}
          maxLength={maxLength}
          onChange={onChange}
          {...other}
        />
      </div>
    </label>
    <span className={styles.inputError}>{errorMessage}</span>
  </div>
);

export default IconInput;
