import React from 'react';
import styles from './index.module.scss';

const Footer = () => {
    return (
        <footer className={styles.footer}>
            <span>Made with Copyright © NANI BOOK ENTERPRISE CO.,LTD. All Rights Reserved.</span>
            <div>version : {process.env.REACT_APP_VERSION || '1.7.1'}</div>
        </footer>
    )
}

export default Footer;