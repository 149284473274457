import React, { useState, useEffect, useCallback } from "react";
import { useStore, StoreTypes } from "context";
import moment from "moment";
import styles from "./index.module.scss";
import {
  getTutorials,
} from "repositories";
import TutorialItem from "components/TutorialItem";
import { LODING_POPUP } from "constants/popupTypes";
import { TUTOR } from "constants/roleType";
import {TablePagination} from '@material-ui/core';

const EditCourse = props => {
  const [, dispatchPopupState] = useStore(StoreTypes.popup);
  const [{ token, role, name }] = useStore(StoreTypes.member);
  const [{ Student }] = useStore(StoreTypes.classSchedule);
  const [classSelected, setclassSelected] = useState("Today");
  const [page,setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [total,setTotal] = useState(0);
  const memberId = props.match.params.userId;
  const [tutorials, setTutorials] = useState([]);
  const tabs = [
    {
      Title: "今日課程",
      Type: "Today"
    },
    {
      Title: "未上課程",
      Type: "Yet"
    },
    {
      Title: "所有課程",
      Type: "All"
    }
  ];
  const nowTime = moment().valueOf();
  const today = moment().format("L");
  const todayValue = moment(today).valueOf();
  const tomorrowValue = moment(today)
    .add("1", "days")
    .valueOf();
  const allDay = moment("2019-11-01").valueOf();

  const enterStateHandler = (startTime, endTime) => {
    if (nowTime > endTime) {
      return "finish";
    } else if (todayValue < startTime && startTime < tomorrowValue) {
      return "enter";
    } else {
      return "yet";
    }
  };
  const getToturialsByDate = async (time, filterTime,limit,skip) => {
    dispatchPopupState({ type: LODING_POPUP, lodingState: true });
    await getTutorials({
      token,
      role,
      memberId: role === TUTOR ? "" : memberId,
      startAfter: time,
      limit,
      skip
    }).then(res => {
      if (res.total === 0) {
        setTutorials([]);
        dispatchPopupState({ type: LODING_POPUP, lodingState: false });
        return;
      }
      const filterResult = filterTime
        ? // eslint-disable-next-line array-callback-return
        res.tutorials.filter(filterItem => {
          if (filterItem.startTime < filterTime) return filterItem;
        })
        : res.tutorials;
      const fullRes = filterResult.map(async (item, index) => {
        item.enterState = enterStateHandler(item.startTime, item.endTime);
        return item;
      });
      setTotal(res.total)
      Promise.all(fullRes).then(result => {
        setTutorials(result);
        dispatchPopupState({ type: LODING_POPUP, lodingState: false });
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };
  const changeTab = type => {
      setPage(0);
      setRowsPerPage(10);
      setclassSelected(type);
  }
  const changePageHandler = (page) => {
    setPage(page);
  };
  const changeRowsPerPageHandler = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  useEffect(() => {
    (async () => {
      if (!token) return;
      if (classSelected === "All") {
        getToturialsByDate(allDay,null,rowsPerPage,page);
      } else if (classSelected === "Yet") {
        getToturialsByDate(tomorrowValue,null,rowsPerPage,page);
      } else if (classSelected === "Today") {
        getToturialsByDate(todayValue, tomorrowValue,rowsPerPage,page);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token,classSelected,page,rowsPerPage]);

  return (
    <section className={styles.shedule}>
      <div className="container">
        <div className={styles.schedule__box}>
          <h2>{role === TUTOR ? "老師課表" : "個人課表"}</h2>
          <div className={styles.schedule__name}>
            歡迎{role === TUTOR ? `${name}老師` : `${Student.Name}同學`}
          </div>
          <ul className={styles.schedule__tab}>
            {tabs.map(item => {
              return (
                <li
                  key={item.Type}
                  className={classSelected === item.Type ? styles.active : ""}
                  onClick={() => {
                    changeTab(item.Type);
                  }}
                >
                  {item.Title}
                </li>
              );
            })}
          </ul>
          <div className={styles.schedule__list}>
            {tutorials.length > 0 &&
              tutorials.map(tutorial => (
                <TutorialItem
                  key={tutorial.id}
                  role={role}
                  data={tutorial}
                  enterState={tutorial.enterState}
                />
              ))}
          </div>
        </div>
      </div>
      {
        total > 0 && 
        <div className={styles.pagination}>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={total || 0}
            rowsPerPage={rowsPerPage}
            page={page}
            labelRowsPerPage='每頁顯示'
            onChangeRowsPerPage={changeRowsPerPageHandler}
            onChangePage={(event, page) => changePageHandler(page)}
          />
        </div>
      }
    </section>
  );
};

export default EditCourse;
