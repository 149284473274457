import React, { useEffect } from "react";
import Man from "static/images/member/student01.jpg";
import Girl from "static/images/member/student02.jpg";
import useSetState from "customHooks/setState";
import styles from "./index.module.scss";
import Icon from "components/common/Icon";
const MemberCard = ({ name, gender, onClick, edit }) => {
  const [{ image }, setImage] = useSetState({ image: "" });

  useEffect(() => {
    switch (gender) {
      case "male":
        setImage({ image: Man });
        break;
      case "female":
        setImage({ image: Girl });
        break;
      default:
        setImage({ image: Man });
        break;
    }
  }, [gender, setImage]);

  return (
    <div className={styles.memberCard}>
      <div className={styles.memberCard__background} onClick={onClick}>
        <img src={image} alt="Member" />
      </div>
      <div className={styles.memberCard__name}>{name}</div>
      <div className={styles.memberCard__icon} onClick={edit}>
        <Icon name="edit" />
      </div>
    </div>
  );
};

export default MemberCard;
