import React, { createContext, useContext } from "react";
import useClassSchedule from "reducers/classScheduleReducer";
import useUserReducer from "reducers/memberReducer";
import useLoginReducer from "reducers/loginReducer";
import usePopupReducer from "reducers/popupReducer";
import useAdminReducer from "reducers/adminReducer";

const Context = createContext();

export const StoreTypes = {
  member: "member",
  classSchedule: "classSchedule",
  loginPopup: "loginPopup",
  popup: "popup",
  admin: "admin",
  router: "router"
};

export const useStore = store => {
  const reducers = useContext(Context);
  if (!store) return reducers;
  if (!Object.keys(StoreTypes).includes(store)) {
    throw new Error(`Store doesn't contain ${store}`);
  }
  return reducers[store];
};

const Provider = ({ children, router }) => {
  const reducers = {
    [StoreTypes.classSchedule]: useClassSchedule(),
    [StoreTypes.member]: useUserReducer(),
    [StoreTypes.loginPopup]: useLoginReducer(),
    [StoreTypes.popup]: usePopupReducer(),
    [StoreTypes.admin]: useAdminReducer(),
    [StoreTypes.router]: router
  };

  return (
    <Context.Provider value={{ ...reducers }}>{children}</Context.Provider>
  );
};

export default Provider;
