import { useEnhanceReducer } from "customHooks/enhanceReducer";
import {
  ADDMEMBER_POPUP,
  LODING_POPUP,
  ALERT_MESSAGE_OPEN,
  ALERT_MESSAGE_CLOSE
} from "constants/popupTypes";

export const initState = {
  popupState: false,
  lodingState: false,
  alertMessage: {
    isShowing: false,
    isActive: false,
    content: ""
  }
};

const popupReducer = (state, action) => {
  switch (action.type) {
    case ADDMEMBER_POPUP:
      return { ...state, popupState: action.popupState };
    case LODING_POPUP:
      return { ...state, lodingState: action.lodingState };
    case ALERT_MESSAGE_OPEN:
      return {
        ...state,
        alertMessage: {
          isShowing: true,
          isActive: true,
          content: action.content
        }
      };
    case ALERT_MESSAGE_CLOSE:
      return {
        ...state,
        alertMessage: { ...state.alertMessage, ...action.alertMessage }
      };
    default:
      return state;
  }
};

const usePopupReducer = () => useEnhanceReducer(popupReducer, initState);

export default usePopupReducer;
