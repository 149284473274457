import React, { useCallback, useEffect, useMemo, useState } from "react";
import Input from "components/common/Input";
import Select from "components/common/Select";
import useSetState from "customHooks/setState";
import { useStore, StoreTypes } from "context";
import {
  createTutorial,
  getUser,
  getSingleTutorial,
  updateTutorial,
  getOneTeacherCode,
  getTutorialsEduSubject,
} from "repositories";
import {
  getPreset
} from 'repositories/admin';
import { LODING_POPUP } from "constants/popupTypes";
import moment from "moment";
import styles from "./index.module.scss";
import InputTime from "components/common/InputTime";
import { convertArrayToMap } from 'utils'
import config from "config";


const AdminCourse = props => {
  const { APP_ONETEACHER_DOMAIN } = config;
  const [{ token }] = useStore(StoreTypes.member);
  const [, dispatchPopupState] = useStore(StoreTypes.popup);
  const [oneTeacherCode,setOneTeacherCode] = useState('');
  const [{ teachers, filterTeachers, searchTeacher }, setTeachers] = useSetState({
    teachers: [],
    filterTeachers: [],
    searchTeacher: '',
  });

  const [{ eduSubject, eduMap, eduGrade },setEduMap] = useSetState({
    eduSubject: {},
    eduSubjectMap: {},
    eduMap: {},
    eduGrade: {}
  })

  const router = useStore(StoreTypes.router);
  const { searchParams } = new URL(window.location.href);
  const urlParams = {};
  urlParams.tutorialId = searchParams.get('tutorialId');
  urlParams.tutorId = searchParams.get('tutorId');
  urlParams.studentId = searchParams.get('studentId');

  const isEdit = urlParams.tutorialId ? true : false;

  const getTeacher = useCallback(async ({ userIdKeyword, displayNameKeyword }) => {
    const params = {
      role: "tutor",
      limit: '20'
    };
    if (userIdKeyword) params.userIdKeyword = userIdKeyword;
    if (displayNameKeyword) params.displayNameKeyword = displayNameKeyword;
    const teachers = await getUser({ params, token });
    const teacherOptions = await teachers.users.map(item => {
      return {
        Text: `${item.displayName}(${item.mobileNumber})`,
        Value: item.id
      };
    });
    setTeachers({
      teachers: [...teacherOptions, {
        Text: '無',
        Value: ''
      }]
    });
  }, [setTeachers, token]);

  const [
    {
      tutorialId,
      tutorId,
      studentId,
      bookId,
      startDate,
      startHM,
      endHM,
      educationName,
      gradeName,
      subjectName,
      subjectCode,
      className,
      phone,
      preExamId,
      postExamId,
    },
    setTutorial
  ] = useSetState({
    tutorialId: "",
    tutorId: "",
    studentId: "",
    bookId: "d6c8b57b7890fbbb",
    startDate: moment().format("YYYY-MM-DD"),
    startTime: 0,
    endTime: 0,
    startHM: "",
    endHM: "",
    educationName: "",
    gradeName: "",
    subjectName: "",
    subjectCode: "",
    className: "",
    phone: "",
    preExamId: "",
    postExamId: "",
  });
  const [{ studentOptions }, setStudentOptions] = useSetState({
    studentOptions: []
  });
  const [
    { startHour, startMinute, endHour, endMinute },
    setInputTime
  ] = useSetState({
    startHour: 0,
    startMinute: 0,
    endHour: 0,
    endMinute: 0
  });
  useEffect(() => {
    (async () => {
      if (!token) return;
      await dispatchPopupState({ type: LODING_POPUP, lodingState: true });
      await getTeacher({ userIdKeyword: urlParams.tutorId });
      // const eduSubject = await getTutorialsEduSubject({ token });
      const category = 'edu_subjects';
      const eduSubject = await getPreset({token,category});  
      setEduMap({ eduSubject })
      if (isEdit) {
        const tutitialInfo = await getSingleTutorial({
          token,
          params: urlParams
        });
        const {
          id,
          bookId,
          endTime,
          info,
          postExamId,
          preExamId,
          startTime,
          students,
          tutorId
        } = tutitialInfo;
        const _startTime = moment(startTime).format("YYYY-MM-DD");
        const _startDate = moment(startTime).format("YYYY-MM-DD");
        const _endTime = moment(endTime).format("YYYY-MM-DD");
        const startHour = moment(startTime).format("HH");
        const startMinute = moment(startTime).format("mm");
        const endHour = moment(endTime).format("HH");
        const endMinute = moment(endTime).format("mm");
        setInputTime({
          startHour,
          startMinute,
          endHour,
          endMinute
        });
        setStudentOptions({
          studentOptions: [
            {
              Text: students[0].studentNickname,
              Value: students[0].studentId
            }
          ]
        });

        const eduSubjectMap = convertArrayToMap(Object.values(eduSubject).flat(),'name');
        const subjectCode = info.subjectCode ? info.subjectCode : eduSubjectMap[info.subjectName].code;

        await setTutorial({
          tutorialId: id,
          tutorId,
          studentId: students[0].studentId,
          bookId,
          startDate: _startDate,
          startTime: _startTime,
          endTime: _endTime,
          educationName: info.educationName,
          gradeName: info.gradeName,
          subjectName: info.subjectName,
          subjectCode,
          className: info.className,
          phone: "",
          preExamId,
          postExamId
        });
      }
      await dispatchPopupState({ type: LODING_POPUP, lodingState: false });
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  const studentFormatOptions = useCallback(
    arr => {
      if (arr.length === 0) {
        alert("查無學生");
        return [];
      }
      const [{ id }] = arr;
      setTutorial({ studentId: id });
      return arr.map(item => {
        return {
          Text: item.nickname,
          Value: item.id
        };
      });
    },
    [setTutorial]
  );

  const searchStudent = async () => {
    if (!phone) {
      alert("未填寫電話號碼");
      return false;
    }
    const params = {
      role: "user",
      mobileNumber: phone
    };
    const res = await getUser({ token, params });
    res.users.length > 0
      ? setStudentOptions({
        studentOptions: await studentFormatOptions(res.users.map(user => user.members).flat())
      })
      : alert("查無此人");
  };

  const goTutorList = () => {
    router.history.goBack();
  };

  const timeCombination = time => {
    return moment(`${startDate}T${time}`).valueOf();
  };

  const submit = async () => {
    if (tutorId === '') {
      alert('請選擇教師！')
      return;
    }

    
    const params = {
      tutorId,
      studentId,
      bookId,
      startTime: timeCombination(startHM),
      endTime: timeCombination(endHM),
      preExamId,
      postExamId,
      info: {
        educationName,
        gradeName,
        subjectCode,
        subjectName,
        className
      }
    };
    const result = isEdit
      ? await updateTutorial({ token, tutorialId: tutorialId, params })
      : await createTutorial({ token, params });
    await dispatchPopupState({ type: LODING_POPUP, lodingState: false });
    const errorMessage = {
      "30005": "課程已存在",
      "30006": "該時間老師已經有課",
      "30007": "該時間學生已經有課",
      "30008": "無效的課程時間",
      "1003": "有欄位未填寫或是錯誤",
      "80000": "編輯課程失敗,請稍候在試！"
    };
    result.errorCode
      ? alert(errorMessage[result.errorCode])
      : alert(`${isEdit ? "課程修改成功" : "課程創建成功"}`);
  };
  const getStartTime = useCallback(value => {
    setTutorial({ startHM: value });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const getEndTime = useCallback(value => {
    setTutorial({ endHM: value });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const searcherTeacherHandler = async () => {
    await dispatchPopupState({ type: LODING_POPUP, lodingState: true });
    await getTeacher({ displayNameKeyword: searchTeacher });
    setTutorial({
      tutorId:''
    })
    await dispatchPopupState({ type: LODING_POPUP, lodingState: false });
  };

  const searchFromOneTeacher = async () => {
    const response = await getOneTeacherCode({ token });
    setOneTeacherCode(response.code)
  }

  useEffect(() => {
    window.addEventListener('message', (event) => {
      if(!event.data) return;
      const data = JSON.parse(event.data);
      setTeachers({
        teachers: [{
          Text: `${data.name}(${data.mobile})`,
          Value: data.id
        }]
      });

      setTutorial({ startDate: moment(data.startTime).format('YYYY-MM-DD') });
      setInputTime({
        startHour: moment(data.startTime).format("HH"),
        startMinute: moment(data.startTime).format("mm"),
        endHour: moment(data.endTime).format("HH"),
        endMinute: moment(data.endTime).format("mm")
      });

      setTutorial({ 
        educationName: data.education, 
        subjectCode: data.subject, 
        tutorId: data.userId 
      });

    }, false);
  },[getEndTime, getStartTime, setInputTime, setTeachers, setTutorial])


  useEffect(() => {
    if(!token) return;
    (async() => {
      const category = 'edu_grades';
      const response = await getPreset({token,category});  
      setEduMap({
        eduGrade: response
      })
    })()
  },[token])

  const educationNameOptions = [
    {
      Text: "國小",
      Value: "E"
    },
    {
      Text: "國中",
      Value: "J"
    },
    {
      Text: "高中",
      Value: "H"
    },
    {
      Text: "通用",
      Value: "General"
    }
  ];

  const gradesOptions = useMemo(() => {
    return (eduGrade[educationName] || []).map(item => ({ Text: item.name, Value : item.code }))
  },[eduGrade, educationName]);

  const subjectOptions = useMemo(() => {
    return (eduSubject[educationName] || []).map(item => ({ Text: item.name, Value : item.code }))
  },[eduSubject, educationName])

  return (
    <div className={styles.admin}>
      <Input
        placeholder={"老師姓名(英文有大小寫之分)"}
        label={"搜尋老師姓名"}
        value={searchTeacher}
        onChange={e => {
          setTeachers({
            searchTeacher: e.target.value
          })
        }}
      />

      <div className={styles.buttonBox}>
        <button className={styles.button} onClick={searcherTeacherHandler}>搜尋</button>
        <button className={styles.button} onClick={searchFromOneTeacher}>OneTeacher搜尋</button>
      </div>

      {
        oneTeacherCode && (
          <div className={styles.oneTeacherFrame}>
            <iframe title="oneTeacher" src={`${APP_ONETEACHER_DOMAIN}/table.html?nanicode=${oneTeacherCode}`} frameborder="0"></iframe>
          </div>
        )
      }
      <Select
        value={tutorId}
        defaultText="請選擇老師"
        label="老師"
        options={teachers}
        onChange={e => {
          setTutorial({ tutorId: e.target.value });
        }}
      />
      <Input
        placeholder={"書本Id"}
        label={"書本Id"}
        value={bookId}
        onChange={e => {
          setTutorial({ bookId: e.target.value });
        }}
      />
      <Input
        placeholder="手機電話號碼"
        label="手機(家長)"
        value={phone}
        onChange={e => {
          setTutorial({ phone: e.target.value });
        }}
      />
      <button className={styles.button} onClick={searchStudent}>搜尋</button>
      <Select
        value={studentId}
        defaultText={"請選擇學生"}
        label={"學生"}
        options={studentOptions}
        onChange={e => {
          setTutorial({ studentId: e.target.value });
        }}
      />
      <Input
        label="課程日期"
        type={"date"}
        value={startDate}
        onChange={e => {
          setTutorial({ startDate: e.target.value });
        }}
      />
      <InputTime
        label="開始時間"
        onChange={value => {
          getStartTime(value);
        }}
        propHour={startHour}
        propMinute={startMinute}
      />
      <InputTime
        label="結束時間"
        onChange={value => {
          getEndTime(value);
        }}
        propHour={endHour}
        propMinute={endMinute}
      />
      <Select
        value={educationName}
        defaultText={"請選擇學制"}
        label={"學制"}
        options={educationNameOptions}
        onChange={e => {
          setTutorial({ educationName: e.target.value });
        }}
      />
      <Select
        value={gradeName}
        defaultText={"請選擇年級"}
        label={"年級"}
        options={gradesOptions}
        onChange={e => {
          setTutorial({ gradeName: e.target.value });
        }}
        disabled={gradesOptions.length < 1}
      />
      <Select
        value={subjectCode}
        defaultText={"請選擇科目"}
        label={"科目"}
        options={subjectOptions}
        onChange={e => {
          setTutorial({ 
            subjectCode: e.target.value
          });
        }}
        disabled={subjectOptions.length < 1}
      />
      <Input
        placeholder={"班級名稱"}
        label={"班級名稱"}
        value={className}
        onChange={e => {
          setTutorial({ className: e.target.value });
        }}
      />
      <Input
        placeholder={"課前測驗id"}
        label={"課前測驗id"}
        value={preExamId}
        onChange={e => {
          setTutorial({ preExamId: e.target.value });
        }}
      />
      <Input
        placeholder={"課後測驗id"}
        label={"課後測驗id"}
        value={postExamId}
        onChange={e => {
          setTutorial({ postExamId: e.target.value });
        }}
      />
      <div className={styles.buttonBox}>
        <button className={styles.button} onClick={goTutorList}>返回上一頁</button>
        <button className={styles.button} onClick={submit}>送出</button>
      </div>
    </div>
  );
};

export default AdminCourse;
