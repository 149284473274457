import React from 'react';
import styles from './index.module.scss';
import { useStore, StoreTypes } from 'context';
import classnames from 'classnames'
const Loading = () => {
    const [{ lodingState }] = useStore(StoreTypes.popup);
    return (
        <section className={classnames(styles.loading__background, lodingState ? styles.show:styles.hidden)}>
            <div className={styles.loading__box}>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </section>
    )
}

export default Loading