import { useEnhanceReducer } from 'customHooks/enhanceReducer';

export const initState = {
    Student:{
        Id:'',
        Name:''
    },
    Shedule:[]
};


const classSheduleReducer = (state,action) => {
    switch (action.type){
        case 'GET_CLASS':
            return { ...state,Shedule:action.Shedule }
        case 'GET_STUDENT':
            return { ...state,Student:action.Student }
        default:
            return state;
    }
};


const useClassShedule = () => useEnhanceReducer(classSheduleReducer, initState);

export default useClassShedule;