import React from "react";
import styles from "./index.module.scss";
import classnames from "classnames";

const Buttton = ({ children, className, ...other }) => {
  return (
    <button className={classnames(styles.button, className)} {...other}>
      {children}
    </button>
  );
};

const ScheduleButton = ({ children, type, onClick }) => {
  return (
    <Buttton className={classnames([styles[type]])} onClick={onClick}>
      {children}
    </Buttton>
  );
};

export default ScheduleButton;
