import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBackward,
  faForward,
  faHeadphones,
  faPlay,
  faPause,
  faTimesCircle,
  faClosedCaptioning,
  faVolumeMute,
  faVolumeDown,
  faVolumeUp,
  faUndo,
  faExpand,
  faEnvelope,
  faTools,
  faAngleLeft,
  faAngleRight,
  faChevronLeft,
  faChevronRight,
  faSearchPlus,
  faHome,
  faLongArrowAltRight,
  faTimes,
  faPencilAlt,
  faPlayCircle,
  faEllipsisV,
  faHandPaper,
  faUser,
  faBars,
  faKey,
  faPlus,
  faSignInAlt,
  faEdit,
  faSync,
  faExclamationCircle,
  faSortDown,
  faCheckCircle,
  faVideo,
  faMicrophone
} from "@fortawesome/free-solid-svg-icons";

const fontawesomeIconMap = {
  backward: faBackward,
  forward: faForward,
  headphones: faHeadphones,
  play: faPlay,
  pause: faPause,
  searchPlus: faSearchPlus,
  times: faTimes,
  timesCircle: faTimesCircle,
  closedCaptioning: faClosedCaptioning,
  volumeMute: faVolumeMute,
  volumeDown: faVolumeDown,
  volumeUp: faVolumeUp,
  undo: faUndo,
  expand: faExpand,
  envelope: faEnvelope,
  tools: faTools,
  angleLeft: faAngleLeft,
  angleRight: faAngleRight,
  chevronLeft: faChevronLeft,
  chevronRight: faChevronRight,
  home: faHome,
  longArrowAltRight: faLongArrowAltRight,
  pencilAlt: faPencilAlt,
  playCircle: faPlayCircle,
  ellipsisV: faEllipsisV,
  handPaper: faHandPaper,
  user: faUser,
  bars: faBars,
  key: faKey,
  plus: faPlus,
  signIn: faSignInAlt,
  edit: faEdit,
  sync: faSync,
  exclamationCircle: faExclamationCircle,
  sortDown: faSortDown,
  circle: faCheckCircle,
  video: faVideo,
  mic: faMicrophone
};

const Icon = ({ className, name, type = "img" }) => (
  <>
    {<FontAwesomeIcon className={className} icon={fontawesomeIconMap[name]} />}
  </>
);

export default Icon;
