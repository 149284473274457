import React, { useState, useEffect } from "react";
import styles from "./index.module.scss";

const InputTime = ({ label, onChange, propHour = 0, propMinute = 0 }) => {
  const [time, setTime] = useState({
    hour: 0,
    minute: 0
  });
  const TimeFormat = str => {
    const resStr = str.length >= 2 ? str : `0${str}`;
    return resStr;
  };
  const timeCombination = (key, value) => {
    const { hour, minute } = time;
    if (key === "hour") {
      value = value > 24 ? 24 : value;
      value = value < 0 ? 0 : value;
    }
    if (key === "minute") {
      value = value > 59 ? 59 : value;
      value = value < 0 ? 0 : value;
    }
    setTime({
      ...time,
      [key]: value
    });

    onChange(
      `${TimeFormat(key === "hour" ? value : hour)}:${TimeFormat(
        key === "minute" ? value : minute
      )}`
    );
  };
  useEffect(() => {
    setTime({
      hour: propHour,
      minute: propMinute
    });
    onChange(`${TimeFormat(propHour)}:${TimeFormat(propMinute)}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [propHour, propMinute]);
  return (
    <div className={styles.selectTime}>
      <span>{label && label}</span>
      <div className={styles.selectTimeContent}>
        <input
          type="number"
          value={time.hour}
          min="0"
          max="24"
          onChange={e => {
            timeCombination("hour", e.target.value);
          }}
        />
        時<div>:</div>
        <input
          type="number"
          value={time.minute}
          min="0"
          max="59"
          onChange={e => {
            timeCombination("minute", e.target.value);
          }}
        />
        分
      </div>
    </div>
  );
};

export default InputTime;
