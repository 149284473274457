import React, { useEffect } from "react";
import { stringify } from 'query-string';
import useSetState from "customHooks/setState";
import { useStore, StoreTypes } from "context";
import Input from "components/common/Input";
import moment from "moment";
import styles from "./index.module.scss";
import { getTokenToOneBook } from "repositories";
import { getTutorialsAdmin, deleteTutorial, startTutorialRecording, stopTutorialRecording } from "repositories";
import ScheduleButton from "components/common/Button";
import { ENTER } from "constants/buttonTypes";
import { LODING_POPUP } from "constants/popupTypes";
import { USER, TUTOR } from "constants/roleType";
import SweetAlert from 'react-bootstrap-sweetalert';
import config from "config";
import Select from "components/common/Select";


const limitOptions = [
  {
    Text: '10',
    Value: 10,
  },
  {
    Text: '20',
    Value: 20,
  },
  {
    Text: '50',
    Value: 50,
  },
  {
    Text: 100,
    Value: 100,
  },
]

const recordingStatusText = {
  ready: '就緒',
  recording: '錄影中',
  stopped: '錄影結束',
  notStart: '課程尚未開始',
  isFinished: '課程已結束'
};

const recordingStatusButtonText = {
  ready: '開始錄影',
  stopped: '開始錄影',
  recording: '結束錄影',
}


const Admin = () => {
  const defaultStatrTime = moment(new Date().toLocaleDateString()).valueOf();
  const defaultEndTime = moment("2039-12-31").valueOf();
  const { APP_CLASS_DOMAIN,APP_ONEBOARD_DOMAIN, APP_ISDEV } = config;
  const [{ token,name,info }] = useStore(StoreTypes.member);
  const [, dispatchPopupState] = useStore(StoreTypes.popup);
  const issafariBrowser =
    /Safari/.test(navigator.userAgent) && !/Chrome/.test(navigator.userAgent);
  const [{ courseList }, setCourseList] = useSetState({
    courseList: []
  });
  const [{ isAlertShow, targetTutorial }, setState] = useSetState({
    isAlertShow: false,
    targetTutorial: {}
  })
  const [
    { startTime, endTime, tutorPhoneNumber, studentPhoneNumber, limit },
    setSearchParams
  ] = useSetState({
    startTime: moment(defaultStatrTime).format("YYYY-MM-DDTHH:mm"),
    endTime: "",
    tutorPhoneNumber: "",
    studentPhoneNumber: "",
    limit: 50,
  });

  const router = useStore(StoreTypes.router);

  const goAddTutorialPage = () => {
    router.history.push(`/AdminCourse`);
  };
  const isTel = value => {
    // eslint-disable-next-line no-useless-escape
    var patrn = /^((\ ?86)|(\(\ 86\)))?\d{3,4}-\d{7,8}(-\d{3,4})?$/;
    if (!patrn.exec(value)) return false;
    return true;
  };
  const dateFormat = (startTime, endTime) => {
    return `${moment(startTime).format("YYYY年MM月DD日 HH:mm")}~${moment(
      endTime
    ).format("HH:mm")}`;
  };
  const infoFormat = info => {
    const educationName = {
      H: "高中",
      J: "國中",
      E: "國小",
      General: '通用'
    };
    return `${educationName[info.educationName]}${info.gradeName ? `${info.gradeName}年級` : ""} ${info.subjectName
      } ${info.className}`;
  };
  const goEdit = (id, tutorId, student) => {
    router.history.push({
      pathname: `/AdminCourse/?tutorialId=${id}&tutorId=${tutorId}&studentId=${student.studentId}`,
      state: { tutorialId: id, tutorId, student }
    });
  };
  const isMobile = value => {
    // eslint-disable-next-line no-useless-escape
    var validateReg = /^((\ ?86)|(\(\ 86\)))?1\d{10}$/;
    return validateReg.test(value);
  };
  const safaiDateFormat = (key, value) => {
    const safariDateFormat = /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/;
    if (issafariBrowser) {
      if (!safariDateFormat.test(value)) {
        setSearchParams({ key: "2019/01/01" });
        alert("時間格式錯誤");
      }
      return;
    }
  };

  const search = async () => {
    const startTimeValue = startTime
      ? moment(startTime).valueOf()
      : defaultStatrTime;
    const endTimeValue = endTime ? moment(endTime).valueOf() : defaultEndTime;
    if (!isTel(tutorPhoneNumber) && isMobile(tutorPhoneNumber)) {
      alert("老師號碼格式錯誤");
      return;
    }
    if (!isTel(studentPhoneNumber) && isMobile(studentPhoneNumber)) {
      alert("學生號碼格式錯誤");
      return;
    }
    await dispatchPopupState({ type: LODING_POPUP, lodingState: true });
    const params = {
      startTime: startTimeValue ? startTimeValue : defaultStatrTime,
      endTime: endTimeValue ? endTimeValue : defaultEndTime,
      tutorPhoneNumber,
      studentPhoneNumber,
      limit,
    };
    const res = await getTutorialsAdmin({ params, token });
    setCourseList({ courseList: res.error ? [] : res.tutorials });
    await dispatchPopupState({ type: LODING_POPUP, lodingState: false });
  };
  const deleteThisTutorial = async tutorialId => {
    const res = await deleteTutorial({ token, tutorialId });
    await search();
    alert(res === "success" ? "刪除課程成功" : "刪除課程失敗");
  };
  const goDetailPage = (role, userId, studentTargetId) => {
    router.history.push({
      pathname: `/adminMemberDetail`,
      state: { role, userId, studentTargetId }
    });
  };
  const tutorialTime = (sec) => {
    const date = new Date(sec);
    const timeString = date.toISOString().substr(11, 5);
    return timeString;
  }
  const openAnswerPage = async testId => {
    if (!token) return;
    const openUrl = window.open("", "tutor");
    const { code } = await getTokenToOneBook(token);
    const url = `http://tutor-exam.oneclass.com.tw/examination.html?range=${testId}&env=${APP_ISDEV ? 'dev' : 'release'}&code=${code}`
    openUrl.location = url;
  }
  const toggleAlertState = tutorial => {
    setState({
      isAlertShow: !isAlertShow,
      targetTutorial: tutorial || {}
    })
  }
  const oneBoardUrl = (tutorial) => {
    const querystring = stringify({
      bookIds: [tutorial.bookId],
      classType: 'single',
      role: 'advisor',
      userName: name,
      userId: info.id
    },{ arrayFormat: 'index' });
    return `${APP_ONEBOARD_DOMAIN}/${tutorial.id}/setup?${querystring}`;
  }
  const changeRecordState = async () => {
    await dispatchPopupState({ type: LODING_POPUP, lodingState: true });
    toggleAlertState();
    const res = targetTutorial.recordingStatus === "recording" ?
      await stopTutorialRecording({ token, tutorialId: targetTutorial.id }) :
      await startTutorialRecording({ token, tutorialId: targetTutorial.id });
    await dispatchPopupState({ type: LODING_POPUP, lodingState: false });
    if (res.errorCode) {
      alert(targetTutorial.recordingStatus === "recording" ? '停止錄影失敗' : '開始錄影失敗');
    } else {
      alert(targetTutorial.recordingStatus === "recording" ? '停止錄影成功' : '開始錄影成功');
      const tutorial = {
        ...targetTutorial,
        recordingStatus: res.status
      }
      const nextCourseList = courseList.map(item => {
        if (item.id === tutorial.id) {
          return {
            ...tutorial
          }
        }
        return item;
      });
      setCourseList({
        courseList: nextCourseList
      })
    }
  }

  const isCanRecord = (status, startTime, endTime) => {
    const nowTime = moment().unix() * 1000;
    if (status !== 'recording' && (nowTime > endTime || startTime > nowTime)) return false
    return true;
  }

  useEffect(() => {
    (async () => {
      if (!token || courseList.length > 0) return;
      dispatchPopupState({ type: LODING_POPUP, lodingState: true });
      const params = {
        startTime: defaultStatrTime,
        endTime: defaultEndTime,
        limit,
      };
      const res = await getTutorialsAdmin({ params, token });

      setCourseList({ courseList: res.error ? [] : res.tutorials });
      await dispatchPopupState({ type: LODING_POPUP, lodingState: false });
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  return (
    <div className={styles.admin}>
      <div className="container">
        <div className={styles.admin__buttonBox}>
          <div className={styles.admin__buttonItem}>
            <ScheduleButton
              onClick={() => {
                goAddTutorialPage();
              }}
              type={ENTER}
            >
              {"新增課程"}
            </ScheduleButton>
          </div>
        </div>
        <div className={styles.admin__filterBox}>
          <Input
            className={styles.admin__input}
            type={"datetime-local"}
            value={startTime}
            label={"開始時間(結束時間也必須存在)"}
            onChange={e => {
              setSearchParams({ startTime: e.target.value });
            }}
            onBlur={e => {
              safaiDateFormat(`startTime`, e.target.value);
            }}
          />
          <Input
            className={styles.admin__input}
            type={"datetime-local"}
            value={endTime}
            label={"結束時間(開始時間也必須存在)"}
            onChange={e => {
              setSearchParams({ endTime: e.target.value });
            }}
            onBlur={e => {
              safaiDateFormat(`endTime`, e.target.value);
            }}
          />
          <Input
            className={styles.admin__input}
            value={tutorPhoneNumber}
            label={"老師電話號碼"}
            placeholder={"同時搜尋聯絡電話與手機"}
            onChange={e => {
              setSearchParams({ tutorPhoneNumber: e.target.value });
            }}
          />
          <Input
            className={styles.admin__input}
            value={studentPhoneNumber}
            label={"學生家長電話號碼"}
            placeholder={"同時搜尋聯絡電話與手機"}
            onChange={e => {
              setSearchParams({ studentPhoneNumber: e.target.value });
            }}
          />
          <Select
            className={styles.admin__input}
            value={limit}
            defaultText={"選擇筆數"}
            label={"筆數"}
            options={limitOptions}
            onChange={e => {
              setSearchParams({ limit: e.target.value });
            }}
          />
        </div>
        <div className={styles.admin__buttonBox}>
          <div className={styles.admin__buttonItem}>
            <ScheduleButton onClick={search} type={ENTER}>
              {"搜尋"}
            </ScheduleButton>
          </div>
        </div>
      </div>
      <table className={styles.table}>
        <tbody>
          <tr>
            <th>上課時間</th>
            <th>老師姓名</th>
            {/* <th>家長手機</th> */}
            <th>學生姓名</th>
            <th>課程資訊</th>
            <th>課程</th>
            <th>前測</th>
            <th>後測</th>
            <th>老師簽到</th>
            <th>學生簽到</th>
            <th>實際下課</th>
            <th>課程時數(時：分)</th>
            <th>錄影狀態</th>
            <th>操作</th>
          </tr>
          {courseList
            ? courseList.map(item => {
              return (
                <tr key={item.id}>
                  <td>{dateFormat(item.startTime, item.endTime)}</td>
                  <td
                    onClick={() => {
                      goDetailPage(TUTOR, item.tutorId);
                    }}
                    className={styles.beClick}
                  >
                    {item.tutorNickname}({item.tutorId})
                    </td>
                  {/* <td>{item.userMobileNumber}</td> */}
                  <td
                    onClick={() => {
                      goDetailPage(
                        USER,
                        item.userId,
                        item.students[0].studentId
                      );
                    }}
                    className={styles.beClick}
                  >
                    {item.students[0].studentNickname}
                  </td>
                  <td>
                    <div>課程id:{item.id}</div>
                    <div>書本id:{item.bookId}</div>
                    <div>資訊:{infoFormat(item.info)}</div>
                  </td>
                  <td>
                    <a href={oneBoardUrl(item)} target="_blank" rel="noopener noreferrer">連結</a>
                    {/* <a href={`${APP_ONEBOARD_DOMAIN}/${item.bookId}?roomId=${item.id}&${moment().unix()}`} target="_blank" rel="noopener noreferrer">連結</a > */}
                  </td >
                  <td>
                    {
                      item.preExamId ?
                        <>
                          <a href={`http://tutor-exam.oneclass.com.tw/analysis.html?range=${item.preExamId}&user=${item.students[0].studentId}&roomId=${item.id}`} target="_blank" rel="noopener noreferrer">測驗結果</a>
                          <div className={styles.pointer} onClick={() => openAnswerPage(item.preExamId)}>測驗答案</div>
                        </>
                        :
                        '無測驗'
                    }
                  </td>
                  <td>
                    {
                      item.postExamId ?
                        <>
                          <a href={`http://tutor-exam.oneclass.com.tw/analysis.html?range=${item.postExamId}&user=${item.students[0].studentId}&roomId=${item.id}`} target="_blank" rel="noopener noreferrer">連結</a>
                          <div className={styles.pointer} onClick={() => openAnswerPage(item.preExamId)}>測驗答案</div>
                        </> :
                        '無測驗'
                    }

                  </td>
                  <td>{item.tutorCheckInAt ? moment(item.tutorCheckInAt).format("YYYY-MM-DD HH:mm:ss") : '--'}</td>
                  <td>{item.studentCheckInAt ? item.studentCheckInAt.map((item,index)=>{
                    return (
                      <div key="index">
                        第{index +1}次簽到:{moment(item).format("YYYY-MM-DD HH:mm:ss")}
                      </div>
                    );
                  }) : "--"}</td>
                  <td>{item.tutorCheckOutAt ? moment(item.tutorCheckOutAt).format("YYYY-MM-DD HH:mm:ss") : "--"}</td>
                  <td>{item.tutorCheckOutAt ? tutorialTime((item.tutorCheckOutAt - item.tutorCheckInAt)) : "--"}</td>
                  <td>{recordingStatusText[item.recordingStatus]}</td>
                  <td>
                    <ScheduleButton
                      onClick={() => {
                        isCanRecord(item.recordingStatus, item.startTime, item.endTime) &&
                          toggleAlertState(item)
                      }}
                      type={isCanRecord(item.recordingStatus, item.startTime, item.endTime) ? "testpreparing" : "tableDisable"}
                    >
                      {recordingStatusButtonText[item.recordingStatus] || '開始錄影'}
                    </ScheduleButton>
                    <ScheduleButton
                      onClick={() => {
                        goEdit(item.id, item.tutorId, item.students[0]);
                      }}
                      type={"table"}
                    >
                      {"編輯課程"}
                    </ScheduleButton>
                    <ScheduleButton
                      onClick={() => {
                        deleteThisTutorial(item.id);
                      }}
                      type={"leave"}
                    >
                      {"刪除課程"}
                    </ScheduleButton>
                  </td>
                </tr >
              );
            })
            : ""}
        </tbody >
      </table >
      <SweetAlert
        title="提醒"
        warning
        show={isAlertShow}
        customButtons={
          <div className={styles.buttonFlexBox}>
            <ScheduleButton onClick={() => changeRecordState()} type={ENTER}>確定</ScheduleButton>
            <ScheduleButton onClick={() => { toggleAlertState() }} type="cancel">取消</ScheduleButton>
          </div>
        }
      >
        確定要開始/結束錄影
      </SweetAlert>
    </div >
  );
};

export default Admin;
