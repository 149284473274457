import React from 'react';
import styles from './index.module.scss';
import onetutorLogo from 'static/images/onetutor.png';

const Logo = () => {
    return (
        <div className={styles.logo}>
            <img src={onetutorLogo} alt={"Logo"}/>
        </div>
    )
}

export default Logo;