import { useEnhanceReducer } from 'customHooks/enhanceReducer';
import { LOGIN_POPUP , LOGIN_BTN } from 'constants/loginTypes';



export const initState = {
    popupState:false,
    loginBtn:false
}



const loginReducer = (state, action) => {
    switch (action.type) {
        case LOGIN_POPUP :
            return { ...state,popupState:action.popupState }
        case LOGIN_BTN :
            return { ...state,loginBtn:action.loginBtn }
        default :
            return state
    }
}


const useLoginReducer = () => useEnhanceReducer(loginReducer, initState);


export default useLoginReducer;