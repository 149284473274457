import React from "react";
import styles from "./index.module.scss";
import Input from "components/common/Input";
import { useStore, StoreTypes } from "context";
import ScheduleButton from "components/common/Button";
import { LODING_POPUP } from "constants/popupTypes";
import useSetState from 'customHooks/setState';
import { adminCreateUser as adminCreateUserApi } from "repositories";

const AdminCreateUser = () => {
  const [{ mobile, password }, setState] = useSetState({
    mobile: '',
    password: ''
  });
  const [{ token }] = useStore(StoreTypes.member);
  const [, dispatchPopupState] = useStore(StoreTypes.popup);
  const router = useStore(StoreTypes.router);

  const submitHandler = async () => {
    const passwordLength = /^\w{8,12}$/;
    const regExp = /^[09]{2}[0-9]{8}$/;
    if (!regExp.test(mobile)) {
      alert("手機號碼格式錯誤");
      return;
    }
    if (!passwordLength.test(password)) {
      alert("密碼長度需介於8~12個字之間。");
      return;
    }
    await dispatchPopupState({ type: LODING_POPUP, lodingState: true });
    const params = {
      mobile,
      password,
      regionCode: "TW"
    }
    const response = await adminCreateUserApi({ params, token });
    if (response.status === 'success') {
      alert('創建成功!')
    } else {
      alert('創建失敗!')
    }
    await dispatchPopupState({ type: LODING_POPUP, lodingState: false });
  };

  return (
    <section className={styles.createUser}>
      <Input
        value={mobile}
        label={"手機號碼"}
        placeholder={"請輸入手機號碼"}
        onChange={e => {
          setState({ mobile: e.target.value });
        }}
      />
      <Input
        value={password}
        label={"密碼"}
        placeholder={"請輸入密碼"}
        onChange={e => {
          setState({ password: e.target.value });
        }}
      />
      <div className={styles.buttonBox}>
        <ScheduleButton onClick={() => router.history.push(`/Admin`)}>{"返回上一頁"}</ScheduleButton>
        <ScheduleButton type="table" onClick={() => submitHandler()}>{"送出"}</ScheduleButton>
      </div>
    </section>
  );
};

export default AdminCreateUser;
