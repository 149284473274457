/* eslint-disable no-unused-vars */
import config from "config";
const { APP_NANI_DOMAIN } = config;
const getTokenValidUrl_for_cookie = APP_NANI_DOMAIN + "tokenValid";
var url_signinforthirdparty = APP_NANI_DOMAIN + "signinforthirdparty";

var KJUR = require("jsrsasign");
var cookieLoginName = "nani_oneclass_login_token";

//checkNaniOneClass 確認登入狀況
//sendData 輸入帳密用
//logoutNaniOneClass 登出用

export const sendData = async (username, password) => {
  var isAccount = checkAccount(username);
  var isPass = checkPass(password);

  if (!isAccount.code) {
    return isAccount;
  }

  if (!isPass.code) {
    return isPass;
  }

  var dataJson = JSON.stringify({
    username: username,
    password: password,
    from: "Nani"
  });

  return fetch(url_signinforthirdparty, {
    method: "POST",
    // headers 加入 json 格式
    headers: {
      "Content-Type": "application/x-www-form-urlencoded"
      // 'Authorization': 'Basic ' + AuthorizationCode
    },
    body: dataJson
  })
    .then(response => {
      // console.log(response);
      if (!response.ok) throw new Error(response.statusText);
      // console.log(response);
      return response.json();
    })
    .then(data => {
      //能進來應該已經是對的 response.ok
      if (data) {
        if (data.code === "SUCCESS") {
          if (data.jwt) {
            var jws = new KJUR.jws.JWS();
            jws.parseJWS(data.jwt);
            var hed = jws.parsedJWS.headS;
            var dte = JSON.parse(hed);
            if (jws.parsedJWS.payloadS) {
              var payloads = JSON.parse(jws.parsedJWS.payloadS);

              //暫時不管控emailvalid
              //   if (payloads.emailvalid) {
              saveTokenData(data);
              return { code: "SUCCESS", jwt: checkNaniLinkLogin() };
              //   } else {
              //     return {
              //       code: "FAILED",
              //       message:
              //         "登入失敗 Email尚未驗證，您的Email為 " + payloads.email
              //     };
              //   }
            } else {
              return { code: "FAILED", message: "登入失敗 Q_Q 資料異常" };
            }
          } else {
            return {
              code: "FAILED",
              message: "登入失敗 Q_Q 登入資料JWT發生異常"
            };
          }
        } else {
          // code: "FAILED"

          return { code: "FAILED", message: "登入失敗 Q_Q 使用者或密碼錯誤" };
        }
      } else {
        return { code: "FAILED", message: "無資料" };
      }
    })
    .catch(err => {
      return { code: "FAILED", message: "登入失敗 發生異常錯誤" };
    });
};

function saveTokenData(token) {
  if (typeof token == "object") {
    setNaniOneClassCookie(cookieLoginName, JSON.stringify(token));
    // localStorage["signInUserSessionForToken"] = JSON.stringify(token);
  } else if (typeof token == "string") {
    setNaniOneClassCookie(cookieLoginName, token);
    // localStorage["signInUserSessionForToken"] = token;
  }
}

export const logoutNaniOneClass = () => {
  deleteNaniOneClassCookie(cookieLoginName);
};

export const getProfile = async params => {
  return await fetch(`${APP_NANI_DOMAIN}getUserProfile`, {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded"
    },
    body: params
  }).then(res => {
    return res.json();
  });
};

function checkNaniLinkLogin() {
  var gettoken = getNaniOneClassCookie(cookieLoginName);
  if (gettoken) {
    return gettoken;
  }
  return "";
}

function getNaniOneClassCookie(name) {
  var v = document.cookie.match("(^|;) ?" + name + "=([^;]*)(;|$)");
  return v ? v[2] : null;
}

function setNaniOneClassCookie(cname, cvalue, exdays) {
  var d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  var expires = "expires=" + d.toUTCString();

  var hostname = window.location.hostname;
  if (hostname.indexOf("oneclass.com.tw") > 0) {
    document.cookie =
      cname + "=" + cvalue + ";" + expires + ";path=/;domain=oneclass.com.tw";
  } else {
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  }
}

var deleteNaniOneClassCookie = function(name) {
  var hostname = window.location.hostname;
  if (hostname.indexOf("oneclass.com.tw") > 0) {
    document.cookie =
      name +
      "=;expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/;domain=oneclass.com.tw";
  } else {
    document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/;";
  }
};

function checkAccount(account) {
  var returnObj = { code: true, message: "" };

  var reAccount = new RegExp(/^[A-Za-z0-9]+$/);
  if (account.replace(/(^\s*)|(\s*$)/g, "").length === 0) {
    returnObj["code"] = "FAILED";
    returnObj["message"] = "請輸入會員帳號";
  }

  return returnObj;
}

function checkPass(password) {
  var returnObj = { code: true, message: "" };

  var re = new RegExp(/^(?=.*[a-zA-Z])(?=.*\d)[a-zA-Z0-9]{8,12}$/);
  if (password.replace(/(^\s*)|(\s*$)/g, "").length === 0) {
    returnObj["code"] = "FAILED";
    returnObj["message"] = "請輸入密碼";
  }

  return returnObj;
}

export const checkNaniOneClass = async callback => {
  var checkToken = checkNaniLinkLogin();

  if (checkToken) {
    try {
      var jsonObj = JSON.parse(checkToken);
      if (jsonObj.jwt) {
        checkTokenValid_for_cookie(jsonObj.jwt, callback);
      } else {
        callback("");
      }
    } catch (error) {
      callback("");
    }
  } else {
    callback("");
  }
};

function checkTokenValid_for_cookie(nanitoken, callback) {
  var dataJson = JSON.stringify(
    // eslint-disable-next-line no-unexpected-multiline
    {
      jwt: nanitoken
    }
  );
  fetch(getTokenValidUrl_for_cookie, {
    method: "POST",
    // headers 加入 json 格式
    headers: {
      "Content-Type": "application/x-www-form-urlencoded"
      // 'Authorization': 'Basic ' + AuthorizationCode
    },
    body: dataJson
  })
    .then(response => {
      // console.log(response);
      if (!response.ok) throw new Error(response.statusText);
      // console.log(response);
      return response.json();
    })
    .then(jsonData => {
      // console.log(jsonData);
      var newdate = new Date().getTime();

      if (jsonData.jwt) {
        callback(jsonData.jwt);
      } else {
        logoutNaniOneClass(function myfunction(result) {
          callback("");
        });
      }
      //能進來應該已經是對的 response.ok
    })
    .catch(err => {
      console.log(err);
      logoutNaniOneClass(function myfunction(result) {
        callback("");
      });
    });
}
