/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import styles from "./index.module.scss";
import IconInput from "components/common/IconInput";
import useSetState from "customHooks/setState";
import { sendData } from "static/js/login";
import LoginEvent from "events/loginEvent";
import { useStore, StoreTypes } from "context";
import { NavLink } from "react-router-dom";
import { ALERT_MESSAGE_OPEN } from "constants/popupTypes";

const LoginPage = () => {
  const [, dispatchPopupState] = useStore(StoreTypes.popup);
  const router = useStore(StoreTypes.router);
  const [{ isLogin, name, role, info: { id: userId } }] = useStore(StoreTypes.member);
  const [{ Account, Password,isLoading }, setUserParams] = useSetState({
    Account: "",
    Password: "",
    isLoading: false
  });
  const handleKeyPress = event => {
    event.key === "Enter" && Submit();
  };
  const [fetchToken] = LoginEvent({ init: false });

  const Submit = async () => {
    if(isLoading) return;
    setUserParams({ isLoading: true });
    const { code, message } = await sendData(Account, Password);
    if (code === "SUCCESS") {
      fetchToken();
      setUserParams({ Account: "", Password: "" });
    } else {
      dispatchPopupState({ type: ALERT_MESSAGE_OPEN, content: message });
      
    }
    setTimeout(()=>{
      setUserParams({ isLoading: false });
    },[1000])
  };

  const linkTo = path => {
    router.history.push(path);
  }

  return (
    <section className={styles.login}>
      {isLogin ? (
        <div className={styles.flexCenterBox}>
          <h2>您好，{name}</h2>
          {(role === 'admin' || role === 'system_admin') && (
            <>
              <div className={styles.login__button} onClick={() => linkTo('/admin')}>課程列表</div>
              <div className={styles.login__button} onClick={() => linkTo('/AdminCourse')}>創建課程</div>
              <div className={styles.login__button} onClick={() => linkTo('/adminMemberList')}>會員列表</div>
              <div className={styles.login__button} onClick={() => linkTo('/adminCreateUser')}>創建會員</div>
            </>
          )}
          {role === 'user' && (
            <>
              <div className={styles.login__button} onClick={() => linkTo('/User')}>選擇學員</div>
              <div className={styles.login__button} onClick={() => linkTo('/answer')}>障礙排除</div>
            </>
          )}
          {role === 'tutor' && (
            <>
              <div className={styles.login__button} onClick={() => linkTo(`/EditCourse/${userId}`)}>課程列表</div>
              <div className={styles.login__button} onClick={() => linkTo('/answer')}>障礙排除</div>
            </>
          )}
          <div className={styles.login__button} onClick={() => router.history.goBack()}>返回上一頁</div>
        </div>
      ) : (
          <div className={styles.login__box}>
            <h3>登入</h3>
            <a
              href="https://oneclub.oneclass.com.tw/"
              target="blank"
              className={styles.login__link}
            >
              ► 使用南一帳號登入 ◄
          </a>
            <IconInput
              IconName={"user"}
              placeholder={"請輸入您的帳號"}
              value={Account}
              onChange={e => setUserParams({ Account: e.target.value })}
              onKeyPress={e => {
                handleKeyPress(e);
              }}
            />
            <IconInput
              type={"password"}
              IconName={"key"}
              placeholder={"請輸入您的密碼"}
              value={Password}
              onChange={e => setUserParams({ Password: e.target.value })}
              onKeyPress={e => {
                handleKeyPress(e);
              }}
            />
            <div
              className={styles.login__btn}
              onClick={() => Submit()}
            >
              登入
          </div>
            <div className={styles.login__otherLink}>
              <NavLink to="/forgetPassword">忘記密碼{">"}</NavLink>
              <NavLink to="/register">立即註冊{">"}</NavLink>
            </div>
          </div>
        )}
    </section>
  );
};

export default LoginPage;
