import React, { useState } from "react";
import styles from "./index.module.scss";
import { useStore, StoreTypes } from "context";
import { createReview } from "repositories";
import { LODING_POPUP, ALERT_MESSAGE_OPEN } from "constants/popupTypes";

const Review = () => {
  const router = useStore(StoreTypes.router);
  const [{ token }] = useStore(StoreTypes.member);
  const [, dispatchPopupState] = useStore(StoreTypes.popup);
  const {
    reviewerName,
    reviewer,
    reviewee,
    tutorialIds
  } = router.location.state;
  const startNumber = [1, 2, 3, 4, 5];
  const [star, setStar] = useState(0);
  const [comment, setComment] = useState("");
  //   const [fakeStart, setFakeStar] = useState(0);
  const reviewStart = num => {
    star === num ? setStar(0) : setStar(num);
  };
  const submit = async () => {
    dispatchPopupState({ type: LODING_POPUP, lodingState: true });
    const params = {
      reviewer,
      reviewee,
      tutorialIds,
      rating: star,
      comment
    };
    const res = await createReview({ params, token });
    const errorMessage = {
      "35005": "課程已評價過!",
      "35004": "課程尚未結束!",
      "35001": "錯誤的權限",
      "35000": "網路問題請稍後在試",
      "30002": "錯誤的課程ID"
    };
    await dispatchPopupState({ type: LODING_POPUP, lodingState: false });
    if (res.errorCode) {
      dispatchPopupState({
        type: ALERT_MESSAGE_OPEN,
        content: errorMessage[res.errorCode] || "欄位錯誤"
      });
      return;
    } else {
      dispatchPopupState({ type: ALERT_MESSAGE_OPEN, content: "評價完成" });
      router.history.goBack();
    }
  };
  return (
    <div className={styles.review}>
      <div className="container">
        <div className={styles.review__form}>
          <h1>評價表</h1>
          <div className={styles.review__border}>
            <hr></hr>
          </div>
          <div className={styles.review__box}>
            <span>被評價者:</span>
            <span>{reviewerName}</span>
          </div>
          <div className={styles.review__box}>
            <span>評分:</span>
            <span>
              {startNumber.map(item => (
                <span
                  className={star >= item ? styles.active : ""}
                  onClick={() => {
                    reviewStart(item);
                  }}
                  key={item}
                >
                  ★
                </span>
              ))}
            </span>
          </div>
          <div className={styles.review__box}>
            <span>備註:</span>
            <textarea onChange={e => setComment(e.target.value)}></textarea>
          </div>
          <div className={styles.review__btnBox}>
            <div
              className={styles.review__btnB}
              onClick={() => {
                router.history.goBack();
              }}
            >
              返回上一頁
            </div>
            <div
              className={styles.review__btnR}
              onClick={() => {
                submit();
              }}
            >
              送出
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Review;
