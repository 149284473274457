import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import EditCourse from "./pages/editCourse";
import Home from "./pages/home";
import MemberFamily from "./pages/memberFamily";
import Admin from "./pages/admin";
import LoginPage from "./pages/login";
import Visitors from "./pages/visitors";
import Provider from "./context";
import LoginEvent from "events/loginEvent";
import Loading from "components/common/Loading";
import Header from "components/header";
import Footer from "components/footer";
import Auth from "components/Auth";
import AdminCourse from "pages/adminCourse";
import AdminCreateUser from 'pages/adminCreateUser';
import Answer from "pages/answer";
import updateProfile from "pages/updateProfile";
import Review from "pages/review";
import AdminMemberList from "pages/adminMemberList";
import MobileProcess from "pages/mobileProcess";
import MemberDetail from "pages/memberDetail";
import AlertMessage from "components/common/AlertMessage";

require("./style/index.scss");

const HookedBrowserRouter = ({ children }) => (
  <BrowserRouter>
    <Route>
      {routeProps => <Provider router={routeProps}>{children}</Provider>}
    </Route>
  </BrowserRouter>
);

const App = () => {
  const loc = window.location.href + "";
  if (loc.indexOf("http://") === 0) {
    window.location.href = loc.replace("http://", "https://");
  }
  return (
    <HookedBrowserRouter>
      <Header />
      <LoginEvent />
      <Loading />
      <AlertMessage />
      <div className="article-content">
        <Switch>
          <Route exact path="/" component={LoginPage} />
          <Route exact path="/Visitors" component={Visitors} />
          <Route exact path="/Answer" component={Answer} />
          <Route exact path="/Review" component={Review} />
          <Route exact path="/Register" component={MobileProcess} />
          <Route exact path="/ForgetPassword" component={MobileProcess} />
          <Auth>
            <Route path="/EditCourse/:userId" component={EditCourse} />
            <Route path="/Home" component={Home} />
            <Route path="/User" component={MemberFamily} />
            <Route path="/updateProfile" component={updateProfile} />
            <Route path="/Admin" component={Admin} />
            <Route path="/AdminCreateUser" component={AdminCreateUser} />
            <Route path="/AdminCourse" component={AdminCourse} />
            <Route path="/adminMemberList" component={AdminMemberList} />
            <Route path="/adminMemberDetail" component={MemberDetail} />
          </Auth>
        </Switch>
      </div>
      <Footer />
    </HookedBrowserRouter>
  );
};

export default App;
