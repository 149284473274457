import React, { useEffect, useCallback } from "react";
import styles from "./index.module.scss";
import classnames from "classnames";
import { useStore, StoreTypes } from "context";
import { ALERT_MESSAGE_CLOSE } from "constants/popupTypes";
import Icon from "components/common/Icon";

const AlertMessage = () => {
  const [{ alertMessage }, dispatchPopupState] = useStore(StoreTypes.popup);
  const { isShowing, isActive, content } = alertMessage;
  const TIME = 1000;
  const OVERTIME = 5000;

  const close = useCallback(() => {
    dispatchPopupState({
      type: ALERT_MESSAGE_CLOSE,
      alertMessage: {
        isActive: false
      }
    });
    setTimeout(() => {
      dispatchPopupState({
        type: ALERT_MESSAGE_CLOSE,
        alertMessage: {
          isShowing: false,
          content: ""
        }
      });
    }, TIME);
  }, [dispatchPopupState]);
  useEffect(() => {
    const timer = setTimeout(() => {
      if (isShowing && isActive) {
        close();
      }
    }, OVERTIME);
    return () => clearTimeout(timer);
  }, [close, isActive, isShowing]);
  return (
    <div className={classnames(styles.alertMessage, isActive && styles.active)}>
      {isShowing && (
        <div
          className={classnames(
            styles.alertMessage_container,
            styles[isActive ? "in" : "out"]
          )}
          onClick={() => {
            close();
          }}
        >
          <div className={styles.alertMessage_content}>
            <Icon name="exclamationCircle" />
            <span>{content}</span>
          </div>
          <button
            onClick={() => {
              close();
            }}
          >
            X
          </button>
          <div
            className={classnames(
              styles.alertMessage_bar,
              styles[isActive ? "in" : "out"]
            )}
          ></div>
        </div>
      )}
    </div>
  );
};

export default AlertMessage;
