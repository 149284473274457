import React, { useState } from "react";
import styles from "./index.module.scss";
import { NavLink } from "react-router-dom";
import Logo from "components/common/Logo";
import { useStore, StoreTypes } from "context";
import { logoutNaniOneClass } from "static/js/login";
import { GET_MEMBER } from "constants/memberType";
import Icon from "components/common/Icon";
import classnames from "classnames";
import { ALERT_MESSAGE_OPEN } from "constants/popupTypes";
import { USER, ADMIN, SYSTEM_ADMIN, TUTOR } from "constants/roleType";
import { CLEAR_MEMBERSTORE } from "constants/adminTypes";

const Header = () => {
  const router = useStore(StoreTypes.router);
  const [, dispatchPopupState] = useStore(StoreTypes.popup);
  const [{ isLogin, name, role, info: { id: userId } }, userDispatcher] = useStore(StoreTypes.member);
  const [, membersDispatch] = useStore(StoreTypes.member);
  const [, adminDispatch] = useStore(StoreTypes.admin);
  const [menuState, setMenuState] = useState(false);
  const logout = () => {
    logoutNaniOneClass();
    userDispatcher({
      type: "UPDATE_USER_INFO",
      isLogin: false,
      userId: "",
      token: "",
      name: "guest",
      role: "guest"
    });
    membersDispatch({ type: GET_MEMBER, members: [] });
    dispatchPopupState({ type: ALERT_MESSAGE_OPEN, content: "登出成功!" });
    adminDispatch({ type: CLEAR_MEMBERSTORE });
    router.history.push(`/`);
    setMenuState(false);
  };
  const openMenu = () => {
    setMenuState(!menuState);
  };
  return (
    <header className={styles.header}>
      <div className="container">
        <div className={styles.header__body}>
          <NavLink to="/" className={styles.header__logoBox}>
            <Logo />
          </NavLink>
          <div className={styles.header__link}>
            {role === TUTOR && <NavLink to={`/EditCourse/${userId}`}>
              課程列表
            </NavLink>
            }
            {(role === ADMIN || role === SYSTEM_ADMIN) && [
              <NavLink key="admin" to="/admin">
                課程列表
              </NavLink>,
              <NavLink key="adminCourse" to="/AdminCourse">創建課程</NavLink>,
              <NavLink key="adminMemberList" to="/adminMemberList">
                會員列表
              </NavLink>,
              <NavLink key="adminCreateUser" to="/adminCreateUser">
                創建會員
              </NavLink>
            ]}
            {role === USER && <NavLink to="/User">選擇學員</NavLink>}
            {role !== ADMIN && (
              <NavLink key="answer" to="/answer">
                障礙排除
              </NavLink>
            )}
          </div>
          {isLogin || (
            <NavLink className={styles.header__openMenu} to="/">
              登入
              <Icon name="sortDown" />
            </NavLink>
          )}

          {isLogin && (
            <div
              className={styles.header__openMenu}
              onClick={() => {
                openMenu();
              }}
            >
              <span>{name}</span>
              <Icon name="sortDown" />
              <ul
                className={classnames(
                  styles.header__menu,
                  menuState && styles.active
                )}
              >
                {role !== "guest" && (
                  <li>
                    <NavLink to="/updateProfile">個人資料</NavLink>
                  </li>
                )}
                {role === ADMIN && [
                  <li className={styles.phone}>
                    <NavLink key="admin" to="/admin">
                      課程列表
                    </NavLink>
                  </li>,
                  <li className={styles.phone}>
                    <NavLink key="adminMemberList" to="/adminMemberList">
                      會員列表
                    </NavLink>
                  </li>
                ]}
                {role === USER && (
                  <li className={styles.phone}>
                    <NavLink to="/User">選擇學員</NavLink>
                  </li>
                )}
                {role !== ADMIN && (
                  <li className={styles.phone}>
                    <NavLink key="answer" to="/answer">
                      障礙排除
                    </NavLink>
                  </li>
                )}
                <li
                  onClick={() => {
                    logout();
                  }}
                >
                  登出
                </li>
              </ul>
            </div>
          )}
        </div>
      </div>
    </header>
  );
};

export default Header;
