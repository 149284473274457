import { stringify } from 'query-string';
import { PreparationAPI } from "api";
import config from "config";
const {
  APP_ONETUTOR_DOMAIN,
} = config;
const SUCCESS = "success";

export const getPreset = async ({ token,category, educationalSystem }) => {
  try {
    const headers = {
      Authorization: token
    };
    let queryParams = {
      category
    };
    const querystring = stringify(queryParams);

    if(educationalSystem){
      queryParams.educationalSystem = educationalSystem;
    }

    const response = await PreparationAPI.get(
      `${APP_ONETUTOR_DOMAIN}/admin/presets?${querystring}`,
      headers
    );
    if (response.status === SUCCESS) {
      return response.data;
    } else {
      return response.error;
    }
  } catch (err) {
    console.log(`post /getPreset error ${err}`);
  }
};