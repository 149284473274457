import { useState } from "react";
const useCheckDevice = () => {
  const [systemState, setSystemState] = useState({
    browser: "",
    system: ""
  });
  const [deviceState, setDeviceState] = useState({
    hasWebcam: false,
    hasMic: false,
    webCamIsActive: false,
    micIsActive: false,
    micName: "",
    webCamName: ""
  });
  const [audioBar, setAudioBar] = useState(0);
  const checkDeviceHandler = () => {
    if (navigator.mediaDevices && navigator.mediaDevices.enumerateDevices) {
      navigator.enumerateDevices = function(callback) {
        navigator.mediaDevices.enumerateDevices().then(callback);
      };
    }
    let MediaDevices = [];
    // eslint-disable-next-line no-restricted-globals
    const isHTTPs = location.protocol === "https:";
    let canEnumerate = false;

    if (
      typeof MediaStreamTrack !== "undefined" &&
      "getSources" in MediaStreamTrack
    ) {
      canEnumerate = true;
    } else if (
      navigator.mediaDevices &&
      !!navigator.mediaDevices.enumerateDevices
    ) {
      canEnumerate = true;
    }
    let hasMicrophone = false;
    // eslint-disable-next-line no-unused-vars
    let hasSpeakers = false;
    let hasWebcam = false;

    let isMicrophoneAlreadyCaptured = false;
    let isWebcamAlreadyCaptured = false;

    function checkDeviceSupport(callback) {
      if (!canEnumerate) {
        return;
      }
      if (
        !navigator.enumerateDevices &&
        window.MediaStreamTrack &&
        window.MediaStreamTrack.getSources
      ) {
        navigator.enumerateDevices = window.MediaStreamTrack.getSources.bind(
          window.MediaStreamTrack
        );
      }

      if (!navigator.enumerateDevices && navigator.enumerateDevices) {
        navigator.enumerateDevices = navigator.enumerateDevices.bind(navigator);
      }

      if (
        navigator.mediaDevices === undefined ||
        navigator.mediaDevices.enumerateDevices === undefined ||
        navigator.mediaDevices.getUserMedia === undefined
      ) {
        return null;
      }

      if (!navigator.enumerateDevices) {
        if (callback) {
          callback();
        }
        return;
      }

      MediaDevices = [];
      navigator.enumerateDevices(function(devices) {
        devices.forEach(function(_device) {
          var device = {};
          for (var d in _device) {
            device[d] = _device[d];
          }

          if (device.kind === "audio") {
            device.kind = "audioinput";
          }

          if (device.kind === "video") {
            device.kind = "videoinput";
          }

          let skip;
          MediaDevices.forEach(function(d) {
            if (d.id === device.id && d.kind === device.kind) {
              skip = true;
            }
          });

          if (skip) {
            return;
          }

          if (!device.deviceId) {
            device.deviceId = device.id;
          }

          if (!device.id) {
            device.id = device.deviceId;
          }
          if (!device.label) {
            device.label = "Please invoke getUserMedia once.";
            if (!isHTTPs) {
              device.label =
                "HTTPs is required to get label of this " +
                device.kind +
                " device.";
            }
          } else {
            if (device.kind === "videoinput" && !isWebcamAlreadyCaptured) {
              isWebcamAlreadyCaptured = true;
            }

            if (device.kind === "audioinput" && !isMicrophoneAlreadyCaptured) {
              isMicrophoneAlreadyCaptured = true;
            }
          }

          if (device.kind === "audioinput") {
            hasMicrophone = true;
          }

          if (device.kind === "audiooutput") {
            hasSpeakers = true;
          }

          if (device.kind === "videoinput") {
            hasWebcam = true;
          }

          // there is no 'videoouput' in the spec.

          MediaDevices.push(device);
        });

        if (callback) {
          callback();
        }
      });
    }
    // check for microphone/camera support!
    checkDeviceSupport(function() {
      navigator.mediaDevices
        .getUserMedia({ audio: true, video: true })
        .then(stream => {
          const videoTracks = stream.getVideoTracks(); //獲得視訊裝置名稱
          const audioTracks = stream.getAudioTracks(); //獲得麥克風裝置名稱
          setDeviceState({
            hasWebcam,
            hasMic: hasMicrophone,
            webCamIsActive: isMicrophoneAlreadyCaptured,
            micIsActive: isWebcamAlreadyCaptured,
            micName: audioTracks[0].label,
            webCamName: videoTracks[0].label
          });
        })
        .catch(err => {
          setDeviceState({
            hasWebcam: false,
            hasMic: false,
            webCamIsActive: isMicrophoneAlreadyCaptured,
            micIsActive: isWebcamAlreadyCaptured
          });
          console.log(err);
        });
    });
  };
  const checkbowersHandler = () => {
    const explorer = navigator.userAgent.toLowerCase();
    const getExplorerInfo = () => {
      //ie
      if (explorer.indexOf("msie") >= 0) {
        const ver = explorer.match(/msie ([\d.]+)/)[1];
        return { type: "IE", version: ver };
      }
      //firefox
      else if (explorer.indexOf("firefox") >= 0) {
        const ver = explorer.match(/firefox\/([\d.]+)/)[1];
        return { type: "Firefox", version: ver };
      }
      //Chrome
      else if (explorer.indexOf("chrome") >= 0) {
        const ver = explorer.match(/chrome\/([\d.]+)/)[1];
        return { type: "Chrome", version: ver };
      }
      //Opera
      else if (explorer.indexOf("opera") >= 0) {
        const ver = explorer.match(/opera.([\d.]+)/)[1];
        return { type: "Opera", version: ver };
      }
      //Safari
      else if (explorer.indexOf("safari") >= 0) {
        if (explorer.indexOf("crios") >= 0) {
          const ver = explorer.match(/crios\/([\d.]+)/)[1];
          alert("如您使用的裝置為IOS系統，請使用Safari繼續接下來的動作!");
          return { type: "Chrome", version: ver };
        }
        const ver = explorer.match(/version\/([\d.]+)/)[1];
        return { type: "safari", version: ver };
      }
      return { type: "no", version: "no" };
    };
    const getSystemInfo = () => {
      const ver = explorer
        .match(/\(.+?\)/g)[0]
        // eslint-disable-next-line no-useless-escape
        .replace(/[\(\)]/g, "")
        // eslint-disable-next-line no-useless-escape
        .replace(/[\_]/g, ".");
      return { sVersion: ver };
    };
    const { type, version } = getExplorerInfo();
    const { sVersion } = getSystemInfo();
    setSystemState({
      browser: `${type}版本${version}`,
      system: `${sVersion}`
    });
  };
  const openWebCam = () => {
    const video = document.querySelector("#videoElement");
    navigator.mediaDevices
      .getUserMedia({ audio: false, video: true })
      .then(stream => {
        video.srcObject = stream; // 視訊
      })
      .catch(err => {
        console.log(err);
      });
  };
  const openAudio = () => {
    // const mystatus = document.querySelector("#audioElement");
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then(stream => {
        let audioContext = new (window.AudioContext ||
          window.webkitAudioContext)();
        let mediaStreamSource = null;
        let scriptProcessor = null;
        mediaStreamSource = audioContext.createMediaStreamSource(stream);
        scriptProcessor = audioContext.createScriptProcessor(4096, 1, 1);
        mediaStreamSource.connect(scriptProcessor);
        scriptProcessor.connect(audioContext.destination);
        scriptProcessor.onaudioprocess = function(e) {
          // 获得缓冲区的输入音频，转换为包含了PCM通道数据的32位浮点数组
          let buffer = e.inputBuffer.getChannelData(0);
          // 获取缓冲区中最大的音量值
          let maxVal = Math.max.apply(Math, buffer);
          // 显示音量值
          setAudioBar(Math.round(maxVal * 100));
        };
      })
      .catch(err => {
        console.log(err);
      });
  };
  return [
    checkDeviceHandler,
    checkbowersHandler,
    systemState,
    deviceState,
    openWebCam,
    openAudio,
    audioBar
  ];
};

export default useCheckDevice;
