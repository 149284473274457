/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useCallback, useEffect } from "react";
import { getMe } from "repositories";
import { checkNaniOneClass } from "static/js/login";
import { useStore, StoreTypes } from "context";
import { LODING_POPUP } from "constants/popupTypes";
import { USER_PROFILE } from "errorCode";
import { TUTOR, USER } from "constants/roleType";

const jwt = require("jsonwebtoken");

const useLoginEvent = ({ init = true }) => {
  const { PROFILE_NO_DATA } = USER_PROFILE;
  const [, userDispatcher] = useStore(StoreTypes.member);
  const [, dispatchLoadingState] = useStore(StoreTypes.popup);
  const router = useStore(StoreTypes.router);

  const initUserInfo = useCallback(
    async token => {
      try {
        if (token) {
          dispatchLoadingState({ type: LODING_POPUP, lodingState: true });
          const { username } = jwt.decode(token);
          const {
            errorCode,
            lastName,
            firstName,
            address,
            birthday,
            gender,
            id,
            mobileNumber,
            nickname,
            phoneNumber,
            role,
            email
          } = await getMe({ token });
          userDispatcher({
            type: "UPDATE_USER_INFO",
            isLogin: true,
            userId: "",
            token,
            name: errorCode
              ? "guest"
              : `${lastName}${firstName}`,
            role: role,
            info: {
              address: address,
              birthday: birthday,
              firstName: firstName,
              gender: gender,
              id: id,
              lastName: lastName,
              mobileNumber: errorCode
                ? username
                : mobileNumber,
              nickname: nickname,
              phoneNumber: phoneNumber,
              role: role,
              email: email
            }
          });
          await dispatchLoadingState({
            type: LODING_POPUP,
            lodingState: false
          });
          if (errorCode === PROFILE_NO_DATA) {
            router.history.push(`Home`);
          } else {
            if (role === TUTOR) {
              router.history.push(`/EditCourse/${id}`);
            }
            if (role === USER) {
              router.history.push(`/User`);
            }
          }
          return;
        }
      } catch (e) {
        await dispatchLoadingState({ type: LODING_POPUP, lodingState: false });
        router.history.push(`/`);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [PROFILE_NO_DATA, dispatchLoadingState, router.history, userDispatcher]
  );

  const fetchToken = useCallback(
    async callback => {
      let token = await new Promise((resolve, reject) => {
        checkNaniOneClass(result => {
          resolve(result);
        }); //
      });
      function getNani_token() {
        if (localStorage["nani_cool_token"]) {
          var nani_cool_token = localStorage["nani_cool_token"];
          return nani_cool_token;
        }
        return "";
      }
      if (!token) {
        token = getNani_token() && JSON.parse(getNani_token()).jwt;
      }
      await initUserInfo(token);
      // callback && callback(role);
    },
    [initUserInfo]
  );

  useEffect(() => {
    init && fetchToken();
  }, [fetchToken, init]);

  return [fetchToken];
};

export default useLoginEvent;
