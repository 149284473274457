import React from "react";
import styles from "./index.module.scss";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import tw from "date-fns/locale/zh-TW";
registerLocale("tw", tw);

const LocalDatePicker = ({ defaultDate, onChange }) => {
  return (
    <DatePicker
      peekNextMonth
      showMonthDropdown
      showYearDropdown
      dropdownMode="select"
      dateFormat="yyyy-MM-dd"
      selected={defaultDate}
      onChange={date => onChange(date)}
      className={styles.localDatePicker}
      locale={tw}
    />
  );
};

export default LocalDatePicker;
