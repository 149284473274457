const config = {
  common: {
    REACT_APP_NODE_ENV: process.env.REACT_APP_NODE_ENV || "development",
    APP_ISDEV: process.env.NODE_ENV !== "production"
  },
  test: {},
  development: {
    APP_ONETUTOR_DOMAIN: "https://asia-east2-onetutor-dev.cloudfunctions.net",
    APP_ONECLASS_DOMAIN:
      "https://u5ptlnmnek.execute-api.ap-northeast-1.amazonaws.com/dev/members",
    APP_USER_VALID_DOMAIN:
      "https://jtlzkwzue6.execute-api.ap-northeast-1.amazonaws.com/dev/members",
    APP_NANI_DOMAIN: "https://api.nani.cool/dev/members/",
    APP_CLASS_DOMAIN: "https://reader-onetutor-dev.oneclass.com.tw",
    APP_TOKEN_DOMAIN: "https://okufvfoft9.execute-api.ap-northeast-1.amazonaws.com/dev",
    APP_ONETEACHER_DOMAIN: "https://oneteacher-dev.oneclass.com.tw",
    APP_ONEBOARD_DOMAIN: "https://oneboard-dev.oneclass.com.tw",
    APP_LOG: true,
  },
  uat: {
    APP_ONETUTOR_DOMAIN: "https://asia-east2-onetutor-uat.cloudfunctions.net",
    APP_ONECLASS_DOMAIN:
      "https://u5ptlnmnek.execute-api.ap-northeast-1.amazonaws.com/dev/members",
    APP_USER_VALID_DOMAIN:
      "https://jtlzkwzue6.execute-api.ap-northeast-1.amazonaws.com/dev/members",
    APP_NANI_DOMAIN: "https://api.nani.cool/dev/members/",
    APP_CLASS_DOMAIN: "https://reader-onetutor-uat.oneclass.com.tw",
    APP_TOKEN_DOMAIN: "https://okufvfoft9.execute-api.ap-northeast-1.amazonaws.com/dev",
    APP_ONETEACHER_DOMAIN: "https://oneteacher-uat.oneclass.com.tw",
    APP_ONEBOARD_DOMAIN: "https://oneboard-uat.oneclass.com.tw",
    APP_LOG: true,
  },
  production: {
    APP_ONETUTOR_DOMAIN: "https://asia-east2-onetutor-release.cloudfunctions.net",
    APP_ONECLASS_DOMAIN:
      "https://u5ptlnmnek.execute-api.ap-northeast-1.amazonaws.com/dev/members",
    APP_USER_VALID_DOMAIN:
      "https://jtlzkwzue6.execute-api.ap-northeast-1.amazonaws.com/dev/members",
    APP_NANI_DOMAIN: "https://api.nani.cool/dev/members/",
    APP_CLASS_DOMAIN: "https://reader-live.oneclass.com.tw",
    APP_TOKEN_DOMAIN: "https://okufvfoft9.execute-api.ap-northeast-1.amazonaws.com/dev",
    APP_ONETEACHER_DOMAIN: "https://oneteacher.oneclass.com.tw",
    APP_ONEBOARD_DOMAIN: "https://oneboard.oneclass.com.tw",
    APP_LOG: false
  }
};

export default Object.assign(
  config.common,
  config[config.common.REACT_APP_NODE_ENV]
);
