import React, { useEffect } from "react";
import styles from "./index.module.scss";
import useSetState from "customHooks/setState";
import { useStore, StoreTypes } from "context";
import { getTutorialsAdminById, getUserById, getMemo, updateMemo } from "repositories";
import { LODING_POPUP } from "constants/popupTypes";
import ScheduleButton from "components/common/Button";
import moment from "moment";
import { USER, TUTOR } from "constants/roleType";

const MemberDetail = () => {
  const [{ token }] = useStore(StoreTypes.member);
  const router = useStore(StoreTypes.router);
  const [, dispatchPopupState] = useStore(StoreTypes.popup);
  const {
    role,
    userId,
    studentTargetId
  } = router.location.state;
  const [{
    memberTutorials,
    tab,
    memberInfo,
    birthday,
    mobileNumber,
    gender,
    email,
    lastName,
    firstName,
    members,
    memos,
    userMemo,
    memo,
  }, setState] = useSetState({
    memberTutorials: [],
    tab: "",
    memberInfo: {},
    birthday: '',
    mobileNumber: '',
    gender: '',
    email: '',
    lastName: '',
    firstName: '',
    members: [],
    memos: [],
    userMemo: '',
    memo: ''
  })
  const genderFormat = {
    male: "男",
    female: "女"
  };
  const tutorialsDate = (start, end) => {
    return (
      moment(start).format("YYYY/MM/DD HH:mm") +
      "~" +
      moment(end).format("HH:mm")
    );
  };
  const tutorialsState = (start, end) => {
    const nowTime = moment().valueOf();
    const half = nowTime - start < 1800000 ? true : false;
    if (nowTime > start && half) {
      return "上課中";
    } else if (end < nowTime) {
      return "課程已結束";
    } else {
      return "課程尚未開始";
    }
  };
  const tutorialsHandler = async id => {
    await dispatchPopupState({ type: LODING_POPUP, lodingState: true });
    const { tutorials, total } = await getTutorialsAdminById({ id, role, token });
    tutorials.forEach(item => {
      item.tutorialsDate = tutorialsDate(item.startTime, item.endTime);
      item.tutorialsState = tutorialsState(item.startTime, item.endTime);
    });
    await dispatchPopupState({ type: LODING_POPUP, lodingState: false });
    setState({ memberTutorials: tutorials });
  };
  const goEdit = (id, tutorId, student) => {
    router.history.push({
      pathname: `/AdminCourse/?tutorialId=${id}&tutorId=${tutorId}&studentId=${student.studentId}`,
      state: { tutorialId: id, tutorId, student }
    });
  };
  const memberInfoHandler = async (memberId, initMemos = []) => {
    setState({ tab: memberId });
    members.forEach(item => {
      if (item.id === memberId) {
        setState({ memberInfo: item });
      }
    });
    if (initMemos.length > 0) {
      initMemos.forEach(item => {
        if (item.memberId === memberId) {
          setState({
            memo: item.content
          })
        }
      })
    } else {
      const memberMemo = memos.filter(item => {
        if (item.memberId) {
          if (item.memberId === memberId) {
            return item;
          }
        }
      });
      setState({
        memo: memberMemo.length > 0 ? memberMemo[0].content : ''
      })
    }
    await tutorialsHandler(memberId);
  };
  const updateMemoHandler = async memberId => {
    await dispatchPopupState({ type: LODING_POPUP, lodingState: true });
    const payload = {
      content: memberId ? memo : userMemo,
    }
    const nextMemos = memos.map((item, index) => {
      if (memberId && item.memberId === memberId) {
        item.content = memo;
      }
      if (!memberId && index === 0) {
        item.content = userMemo;
      }
      return item;
    })
    setState({
      memos: nextMemos
    })
    if (memberId) payload.memberId = memberId;
    await updateMemo({ payload, userId, token });
    await dispatchPopupState({ type: LODING_POPUP, lodingState: false });
  }
  const getUserProfile = async userId => {
    const {
      birthday,
      mobileNumber,
      gender,
      email,
      lastName,
      firstName,
      members,
    } = await getUserById({ userId, token });
    setState({
      birthday,
      mobileNumber,
      gender,
      email,
      lastName,
      firstName,
      members: members || [],
    });
  }
  useEffect(() => {
    if (!token) return;
    getUserProfile(userId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId, token]);
  useEffect(() => {
    if (!userId || !role) {
      router.history.goBack();
    }
  }, [])
  useEffect(() => {
    (async () => {
      if (!token) return;
      const res = await getMemo({ userId, token });
      setState({
        memos: res
      })
      if (role === USER) {
        setState({
          userMemo: (res[0] && !res[0].memberId) ? res[0].content : ''
        });
        if (members.length === 0) {
          await dispatchPopupState({ type: LODING_POPUP, lodingState: false });
          return;
        }
        const memberId = members[0].id;

        await memberInfoHandler(memberId, res);
      } else {
        setState({
          userMemo: res[0] ? res[0].content : ''
        })
        await tutorialsHandler(role === USER ? studentTargetId : userId);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, members]);

  return (
    <div className={styles.memberDetail}>
      <div className={styles.top}>
        <table className={styles.userProfile}>
          <tbody>
            <tr>
              <th>姓名</th>
              <th>性別</th>
              <th>手機號碼</th>
              <th>E-mail</th>
              <th>生日</th>
            </tr>
            <tr>
              <td>{lastName + firstName}</td>
              <td>{genderFormat[gender]}</td>
              <td>{mobileNumber}</td>
              <td>{email}</td>
              <td>{birthday}</td>
            </tr>
          </tbody>
        </table>
        <button
          className={styles.button}
          onClick={() => {
            router.history.goBack();
          }}
        >
          回到列表
        </button>
        <div className={styles.memo}>
          會員備註
          <textarea value={userMemo} onChange={e => setState({ userMemo: e.target.value })}></textarea>
          <button
            className={styles.button}
            onClick={() => updateMemoHandler()}
          >
            儲存
          </button>
        </div>
      </div>
      {(members && members.length > 0) && (
        <ul className={styles.tab}>
          {members.map(member => (
            <li
              className={tab === member.id ? styles.active : ''}
              onClick={() => {
                memberInfoHandler(member.id);
              }}
              key={member.id}
            >
              {member.nickname}
            </li>
          ))}
        </ul>
      )}
      {role === TUTOR && (
        <ul className={styles.tab}>
          <li className={styles.active}>課表</li>
        </ul>
      )}
      <div className={styles.bottom}>
        {role === USER && members.length > 0 && (
          <table className={styles.userMemberInfo}>
            <tbody>
              <tr>
                <th>性別</th>
                <th>學校</th>
                <th>年級</th>
                <th>生日</th>
              </tr>
              <tr>
                <td>{memberInfo.gender}</td>
                <td>{memberInfo.schoolName}</td>
                <td>{memberInfo.gradeName}</td>
                <td>{memberInfo.birthday}</td>
              </tr>
            </tbody>
          </table>
        )}
        {(role === USER && members.length > 0) &&
          <div className={styles.memo}>
            學員備註
            <textarea value={memo} onChange={e => setState({ memo: e.target.value })}></textarea>
            <button
              className={styles.button}
              onClick={() => updateMemoHandler(tab)}
            >
              儲存
          </button>
          </div>
        }
        {memberTutorials.length > 0 && (
          <table className={styles.userMemberTutorials}>
            <tbody>
              <tr>
                <th>日期</th>
                <th>{role === USER ? "老師" : "學生"}</th>
                <th>科目</th>
                <th>年級</th>
                <th>書本</th>
                <th>課程名稱</th>
                <th>課前測驗</th>
                <th>課後測驗</th>
                <th>課程狀況</th>
                <th>課程編輯</th>
              </tr>
              {memberTutorials.map(item => (
                <tr key={item.id}>
                  <td>{item.tutorialsDate}</td>
                  <td>
                    {role === USER
                      ? `${item.tutorNickname}(${item.tutorId})`
                      : `${item.students[0].studentNickname}(${item.userMobileNumber})`}
                  </td>
                  <td>{item.info.subjectName}</td>
                  <td>{item.info.gradeName}</td>
                  <td>{item.bookId}</td>
                  <td>{item.info.className}</td>
                  <td>{item.preExamId || '--'}</td>
                  <td>{item.postExamId || '--'}</td>
                  <td>{item.tutorialsState}</td>
                  <td>
                    <ScheduleButton
                      onClick={() => {
                        goEdit(item.id, item.tutorId, item.students[0]);
                      }}
                      type={"table"}
                    >
                      {"編輯"}
                    </ScheduleButton>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};

export default MemberDetail;
