import React from "react";
import classnames from "classnames";
import styles from "./index.module.scss";

const Input = ({
  type = "text",
  className,
  value = "",
  label,
  onChange,
  onBlur,
  maxLength = 50,
  onKeyDown = () => {},
  ...other
}) => (
  <div className={classnames(styles.inputWrapper, className)}>
    <label>
      {label && <div> {label} </div>}
      <input
        type={type}
        value={value}
        onKeyDown={e => {
          onKeyDown(e);
          e.stopPropagation();
        }}
        onChange={onChange}
        onBlur={onBlur}
        {...other}
        maxLength={maxLength}
      />
    </label>
  </div>
);

export default Input;
