/* eslint-disable no-empty-pattern */
import React, { useState, useEffect } from "react";
import styles from "./index.module.scss";
import IconInput from "components/common/IconInput";
import Button from "components/common/Button";
import {
  phoneRegister,
  accountIsExsit,
  checkMobileValid,
  createMobileAccount,
  forgetPassword,
  passwordCheckMobileValid,
  revisePassword
} from "repositories";
import { useStore, StoreTypes } from "context";
import { LODING_POPUP, ALERT_MESSAGE_OPEN } from "constants/popupTypes";
import classnames from "classnames";

const MobileProcess = () => {
  const router = useStore(StoreTypes.router);
  const { pathname } = router.location;
  const PAGE_TYPE = pathname.split("/")[1];
  const REGISTER = "register";
  const [{}, dispatchPopupState] = useStore(StoreTypes.popup);
  const [phone, setPhone] = useState("");
  const [VerificationCode, setVerificationCode] = useState("");
  const [passwordForm, setPasswordForm] = useState({
    password: {
      value: "",
      errorMessage: ""
    },
    checkPassword: {
      value: "",
      errorMessage: ""
    }
  });
  const [timeCount, setTimeCount] = useState({
    time: 60,
    type: false
  });
  let timeSecond = timeCount.time;
  let timeChange;
  const [step, setStep] = useState("checkPhoneNumber");
  const [errorMessage, setErrorMessage] = useState({
    state: false,
    message: ""
  });
  const pageTitle = {
    checkPhoneNumber: PAGE_TYPE === REGISTER ? "註冊新帳號" : "忘記密碼",
    checkPhoneVerificationCode: "手機驗證",
    checkPassword: "密碼確認"
  };
  const hiddenNumber = () => {
    return phone;
  };
  const setPasswordFromHandler = (key, value, errorMessage = "") => {
    setPasswordForm({
      ...passwordForm,
      [key]: {
        value,
        errorMessage
      }
    });
  };
  const loadingDispatch = state => {
    dispatchPopupState({ type: LODING_POPUP, lodingState: state });
  };
  const CheckPhoneNumberHandler = async () => {
    const regExp = /^[09]{2}[0-9]{8}$/;
    if (!regExp.test(phone)) {
      setErrorMessage({
        state: true,
        message: "*手機號碼格式錯誤，請重新輸入"
      });
      return;
    } else {
      setErrorMessage({
        state: false,
        message: ""
      });
    }
    const params = {
      username: phone
    };
    await loadingDispatch(true);
    const response = await accountIsExsit(params);
    if (response === "使用者已存在") {
      setErrorMessage({
        state: true,
        message: "*該手機帳戶已存在"
      });
      await loadingDispatch(false);
      if (PAGE_TYPE !== REGISTER) {
        const params = {
          mobile: phone,
          regionCode: "TW"
        };

        const result = await forgetPassword(params);
        await loadingDispatch(false);
        if (result.code === "SUCCESS") {
          setStep("checkPhoneVerificationCode");
        }
      }
      return;
    } else {
      const params = {
        mobile: phone,
        regionCode: "TW"
      };
      const result = await phoneRegister(params);
      await loadingDispatch(false);
      if (result.code === "SUCCESS") {
        setStep("checkPhoneVerificationCode");
      }else{
        setErrorMessage({
          state: true,
          message: "*手機號碼錯誤，請重新輸入"
        });
      }
    }
    await loadingDispatch(false);
  };
  const CheckPhoneVerificationCodeHandler = async () => {
    if (VerificationCode === "") {
      dispatchPopupState({
        type: ALERT_MESSAGE_OPEN,
        content: "驗證碼尚未填寫"
      });
      return;
    }
    const checkVaildParams = {
      mobile: phone,
      eventCode: VerificationCode
    };
    await loadingDispatch(true);
    const checkRespense =
      PAGE_TYPE === REGISTER
        ? await checkMobileValid(checkVaildParams)
        : await passwordCheckMobileValid(checkVaildParams);
    await loadingDispatch(false);
    if (checkRespense.code === "SUCCESS") {
      setStep("checkPassword");
    }
  };
  const CheckPasswordHandler = async () => {
    const passwordLength = /^\w{8,12}$/;
    if (!passwordLength.test(passwordForm.password.value)) {
      setPasswordFromHandler("password", "", "密碼長度需介於8~12個字之間。");
      return;
    }
    if (passwordForm.password.value !== passwordForm.checkPassword.value) {
      setPasswordFromHandler("checkPassword", "", "密碼不一致，請重新輸入。");
      return;
    } else {
      setPasswordFromHandler(
        "checkPassword",
        passwordForm.checkPassword.value,
        ""
      );
    }
    const createMobileAccountParams = {
      mobile: phone,
      eventCode: VerificationCode,
      password: passwordForm.password.value
    };
    await loadingDispatch(true);
    const createMobileAccountResult =
      PAGE_TYPE === REGISTER
        ? await createMobileAccount(createMobileAccountParams)
        : await revisePassword(createMobileAccountParams);
    await loadingDispatch(false);
    if (createMobileAccountResult.code === "SUCCESS") {
      dispatchPopupState({
        type: ALERT_MESSAGE_OPEN,
        content: PAGE_TYPE === REGISTER ? "註冊成功!" : "修改成功!"
      });
      router.history.push(`/`);
    }
  };
  const singUpSubmit = async () => {
    switch (step) {
      case "checkPhoneNumber":
        CheckPhoneNumberHandler();
        break;
      case "checkPhoneVerificationCode":
        CheckPhoneVerificationCodeHandler();
        break;
      case "checkPassword":
        CheckPasswordHandler();
        break;
      default:
        break;
    }
  };
  const sendVerificationCode = () => {
    if (timeCount.time !== 0) {
      return;
    }
    CheckPhoneNumberHandler();
    timeSecond = 60;
    timeStart();
  };
  const showTime = () => {
    timeSecond = timeSecond - 1;
    setTimeCount({
      time: timeSecond,
      type: timeSecond === 0 ? true : false
    });
    if (timeSecond === 0) {
      clearInterval(timeChange);
    }
  };
  const timeStart = () => {
    timeChange = setInterval(() => {
      showTime();
    }, 1000);
  };

  useEffect(() => {
    if (step === "checkPhoneVerificationCode") {
      timeStart();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step]);
  return (
    <div className={styles.mobileProcess}>
      <div className="container">
        <div className={styles.mobileProcess__form}>
          <div className={styles.mobileProcess__stepbar}>
            <div
              className={classnames(
                styles.mobileProcess__stepbarItem,
                step === "checkPhoneNumber" && styles.active
              )}
            >
              {PAGE_TYPE === REGISTER ? "註冊新帳號" : "忘記密碼"}
            </div>
            <div
              className={classnames(
                styles.mobileProcess__stepbarItem,
                step === "checkPhoneVerificationCode" && styles.active
              )}
            >
              手機驗證
            </div>
            <div
              className={classnames(
                styles.mobileProcess__stepbarItem,
                step === "checkPassword" && styles.active
              )}
            >
              密碼確認
            </div>
          </div>
          <h1>{pageTitle[step]}</h1>
          <div className={styles.mobileProcess__border}>
            <hr></hr>
          </div>
          {step === "checkPhoneNumber" && (
            <div className={styles.mobileProcess__step}>
              <IconInput
                IconName={"user"}
                placeholder={"請輸入您的手機號碼"}
                value={phone}
                label="手機號碼:"
                onChange={e => setPhone(e.target.value)}
              />
              {errorMessage.state && (
                <div className={styles.mobileProcess__mark}>
                  {errorMessage.message}
                </div>
              )}
            </div>
          )}
          {step === "checkPhoneVerificationCode" && (
            <div className={styles.mobileProcess__step}>
              <span className={styles.mobileProcess__phone}>
                已將驗證碼以簡訊傳送至
                <br />
                {hiddenNumber()}
              </span>
              <IconInput
                IconName={"key"}
                placeholder={"請輸入驗證碼(600秒內輸入完畢)"}
                value={VerificationCode}
                label="驗證碼:"
                onChange={e => setVerificationCode(e.target.value)}
              />
              <div
                className={classnames(
                  styles.mobileProcess__sendMessage,
                  timeCount.type === true && styles.active
                )}
                onClick={() => {
                  sendVerificationCode();
                }}
              >
                重新傳送{timeCount.type === false && `(${timeCount.time}秒)`}
              </div>
            </div>
          )}
          {step === "checkPassword" && (
            <div className={styles.mobileProcess__step}>
              <IconInput
                IconName={"key"}
                type="password"
                placeholder={"請輸入八位以上英數字(大小寫視為不同)"}
                value={passwordForm.password.value}
                label="請輸入密碼:"
                maxLength="12"
                erroeMessage={passwordForm.password.errorMessage}
                onChange={e =>
                  setPasswordFromHandler("password", e.target.value)
                }
              />
              <IconInput
                IconName={"key"}
                type="password"
                placeholder={"請輸入八位以上英數字(大小寫視為不同)"}
                value={passwordForm.checkPassword.value}
                label="請再次輸入密碼:"
                maxLength="12"
                errorMessage={passwordForm.checkPassword.errorMessage}
                onChange={e =>
                  setPasswordFromHandler("checkPassword", e.target.value)
                }
              />
            </div>
          )}
          <Button
            className={styles.mobileProcess__button}
            type={"submit"}
            onClick={() => {
              singUpSubmit();
            }}
          >
            下一步
          </Button>
        </div>
      </div>
    </div>
  );
};

export default MobileProcess;
