import React, { useEffect, useState } from "react";
import styles from "./index.module.scss";
import { useStore, StoreTypes } from "context";
import ScheduleButton from "components/common/Button";
import { LODING_POPUP } from "constants/popupTypes";
import { ADMIN_MEMBERLIST } from "constants/adminTypes";
import { getUser } from "repositories";
import UpdateProfile from "components/updateProfile";
import Select from "components/common/Select";
import Input from "components/common/Input";
import useSetState from "customHooks/setState";
import moment from "moment";
import { USER } from "constants/roleType";
import TablePagination from '@material-ui/core/TablePagination';

const AdminMemberList = () => {
  const [{ token, role: memberRole }] = useStore(StoreTypes.member);
  const router = useStore(StoreTypes.router);
  const [{ popupState, lodingState }, dispatchPopupState] = useStore(StoreTypes.popup);
  const [{ memberList, total }, dispatchAdminState] = useStore(StoreTypes.admin);
  const [role, setRole] = useState("");
  const [profile, setProfile] = useState({});
  const [
    { searchRole, searchmobile, searchAccount,displayNameKeyword },
    setSearchParams
  ] = useSetState({
    searchRole: "all",
    searchmobile: "",
    searchAccount: "",
    displayNameKeyword:""
  });
  const [filterType, setFilterType] = useState('noFilterType');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selectRoleOptions, setSelectRoleOptions] = useState([]);
  const [filterState, setFilterState] = useState(false);

  useEffect(() => {
    if (memberRole === "system_admin") {
      setSelectRoleOptions(
        [{
          Text: "全部",
          Value: "all"
        }, {
          Text: "管理員",
          Value: "admin"
        },
        {
          Text: "老師",
          Value: "tutor"
        },
        {
          Text: "家長",
          Value: "user"
        }])
    } else {
      setSelectRoleOptions(
        [{
          Text: "全部",
          Value: "all"
        },
        {
          Text: "老師",
          Value: "tutor"
        },
        {
          Text: "家長",
          Value: "user"
        }]
      )
    }
  }, [memberRole])

  const selectFilterTypeOptions = [
    {
      Text: '無',
      Value: 'noFilterType'
    }, {
      Text: '手機號碼',
      Value: 'phone'
    }, {
      Text: '帳號',
      Value: 'account'
    }
  ]
  const userRole = {
    admin: "管理員",
    user: "家長",
    tutor: "老師"
  };
  const education = {
    E: "國小",
    J: "國中",
    H: "高中"
  };
  const grade = {
    1: "一年級",
    2: "二年級",
    3: "三年級",
    4: "四年級",
    5: "五年級",
    6: "六年級"
  };
  const setSearchHandler = (key, value) => {
    setSearchParams({ [key]: value });
  };
  const filterUser = async (params = { role: USER }) => {
    if (!token) return;

    await dispatchPopupState({ type: LODING_POPUP, lodingState: true });
    const res = await getUser({ token, params });
    const memberList_final = res.users.sort((a, b) => {
      return (a.createdAt || 1) > (b.createdAt || 1) ? -1 : 1;
    });
    await dispatchAdminState({
      type: ADMIN_MEMBERLIST,
      memberList: memberList_final,
      total: res.total
    });
    await dispatchPopupState({ type: LODING_POPUP, lodingState: false });
  };
  const search = (page) => {
    const defaultPage = 0;
    if (!page) {
      setPage(0)
    }
    const params = {};
    if (searchmobile !== '' || searchAccount !== '') {
      switch (filterType) {
        case 'phone':
          params.mobileNumber = searchmobile;
          break;
        case 'account':
          params.userIdKeyword = searchAccount;
          break;
        default:
          break;
      }
    }
    if (searchRole !== "all") {
      params.role = searchRole;
    }

    if(searchRole === 'tutor'){
      if(displayNameKeyword !== '')params.displayNameKeyword = displayNameKeyword;
      params.includingNickname = true;
    }

    params.skip = page * rowsPerPage || defaultPage;
    params.limit = rowsPerPage;
    filterUser(params);
    setFilterState(true);
  };
  const closeHandler = () => {
    dispatchPopupState({
      type: "ADDMEMBER_POPUP",
      popupState: false
    });
  };
  const momentHandler = time => {
    if (!time) return "無資料";
    return moment(time).format("YYYY-MM-DD");
  };
  const openPopup = (role, data) => {
    setRole(role);
    setProfile(data);
    dispatchPopupState({
      type: "ADDMEMBER_POPUP",
      popupState: true
    });
  };
  const goDetailPage = data => {
    const { role, id, members } = data;
    router.history.push({
      pathname: `/adminMemberDetail`,
      state: { role, userId: id, studentTargetId: members[0] ? members[0].id : '' }
    });
  };

  // 改變每頁顯示幾筆
  const changeRowsPerPageHandler = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  // 換頁
  const changePageHandler = (page) => {
    setPage(page);
  };

  const setFilterTypeHandler = (value) => {
    setFilterType(value);
  }

  useEffect(() => {
    switch (filterType) {
      case 'phone':
        setSearchParams({
          searchAccount: ''
        });
        break;
      case 'account':
        setSearchParams({
          searchmobile: ''
        });
        break;
      default:
        setSearchParams({
          searchmobile: '',
          searchAccount: ''
        });
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterType])

  useEffect(() => {
    if (!filterState) return;
    search();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowsPerPage])

  useEffect(() => {
    if (!filterState) return;
    search(page);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page])

  return (
    <div className={styles.adminMember}>
      <div className="container">
        <h1>會員列表</h1>
        <div className={styles.admin__border}>
          <hr></hr>
        </div>
        <div className={styles.selectTitle}>請選擇身分</div>
        <Select
          value={searchRole}
          options={selectRoleOptions}
          onChange={e => {
            setSearchHandler("searchRole", e.target.value);
          }}
        />
        <div className={styles.selectTitle}>請選擇篩選條件</div>
        <Select
          value={filterType}
          options={selectFilterTypeOptions}
          onChange={e => {
            setFilterTypeHandler(e.target.value);
          }}
        />
        {filterType === 'phone' && <Input
          placeholder={"請輸入手機號碼"}
          onChange={e => {
            setSearchHandler("searchmobile", e.target.value);
          }}
          value={searchmobile}
          maxLength={20}
        />}
        {filterType === 'account' && <Input
          placeholder={"請輸入帳號"}
          onChange={e => {
            setSearchHandler("searchAccount", e.target.value);
          }}
          value={searchAccount}
          maxLength={20}
        />}
        {searchRole ==='tutor' && 
        <>
          <div className={styles.selectTitle}>姓名搜尋</div>
          <Input
            placeholder={"請輸入姓名"}
            onChange={e => {
              setSearchHandler("displayNameKeyword", e.target.value);
            }}
            value={displayNameKeyword}
            maxLength={20}
          />
        </>}
        <div
          onClick={() => {
            search();
          }}
          className={styles.adminMember__btn}
        >
          送出
        </div>
        {memberList.length > 0 && <div className={styles.paginationBlock}>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={total}
            rowsPerPage={rowsPerPage}
            page={page}
            labelRowsPerPage='每頁顯示'
            onChangeRowsPerPage={changeRowsPerPageHandler}
            onChangePage={(event, page) => changePageHandler(page)}
          />
        </div>}
        {memberList.length > 0 &&
          memberList.map(item => (
            <table key={item.id} className={styles.table}>
              <tbody>
                {/* <tr className={styles.user}>
                  <th colSpan="5">會員資料</th>
                </tr> */}
                <tr>
                  <th>創立時間</th>
                  <th>姓名</th>
                  <th>手機</th>
                  <th>身分</th>
                  <th>Email</th>
                  <th>修改</th>
                  <th>詳細</th>
                </tr>
                <tr>
                  <td>{momentHandler(item.createdAt)}</td>
                  <td className={styles.centerWidth}>
                    {item.lastName + item.firstName}({item.id})<br/>
                    暱稱: {item.nickname ||'無'}
                  </td>
                  <td>{item.mobileNumber}</td>
                  <td>{userRole[item.role]}</td>
                  <td className={styles.bigWidth}>
                    {item.email || "尚未填寫"}
                  </td>
                  <td>
                    <ScheduleButton
                      onClick={() => {
                        openPopup(item.role, item);
                      }}
                      type={"table"}
                    >
                      {"編輯"}
                    </ScheduleButton>
                  </td>
                  <td>
                    <ScheduleButton
                      onClick={() => {
                        goDetailPage(item);
                      }}
                      type={"table"}
                    >
                      {"詳細"}
                    </ScheduleButton>
                  </td>
                </tr>
                {item.members.length > 0 && item.role === USER && (
                  <>
                    <tr className={styles.members}>
                      <th colSpan={item.role === USER ? "7" : "6"}>學員資料</th>
                    </tr>
                    <tr>
                      <th colSpan={item.role === USER ? "2" : "1"}>暱稱</th>
                      <th>性別</th>
                      <th colSpan="2">就讀學校</th>
                      <th>生日</th>
                      <th>修改</th>
                    </tr>
                  </>
                )}

                {item.members.length > 0 &&
                  item.role === USER &&
                  item.members.map(member => (
                    <tr key={member.id}>
                      <td colSpan={item.role === USER ? "2" : "1"}>
                        {member.nickname}
                      </td>
                      <td>{member.gender === "male" ? "男" : "女"}</td>
                      <td colSpan="2">
                        {member.cityName +
                          member.districtName +
                          member.schoolName +
                          education[member.educationName] +
                          grade[member.gradeName]}
                      </td>
                      <td>{member.birthday}</td>
                      <td>
                        <ScheduleButton
                          onClick={() => {
                            openPopup("member", member);
                          }}
                          type={"table"}
                        >
                          {"編輯"}
                        </ScheduleButton>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          ))}
        {memberList.length >= 10 && <div className={styles.paginationBlock}>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={total}
            rowsPerPage={rowsPerPage}
            page={page}
            labelRowsPerPage='每頁顯示'
            onChangeRowsPerPage={changeRowsPerPageHandler}
            onChangePage={(event, page) => changePageHandler(page)}
          />
        </div>}
        {memberList.length === 0 && filterState && !lodingState && <div className={styles.searchMessageBlock}>無搜尋結果</div>}
      </div>
      {popupState && (
        <div className={styles.adminMember__popup}>
          <div className={styles.adminMember__popupContent}>
            <div
              className={styles.adminMember__popupClose}
              onClick={() => {
                closeHandler();
              }}
            >
              X
            </div>
            <UpdateProfile role={role} profile={profile} memberRole={memberRole} reFilterFunc={search} />
          </div>
        </div>
      )}
    </div>
  );
};

export default AdminMemberList;
