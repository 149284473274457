import { useEnhanceReducer } from "customHooks/enhanceReducer";
import { ADMIN_MEMBERLIST, CLEAR_MEMBERSTORE } from "constants/adminTypes";

export const initState = {
  memberList: [],
  total: 0
};

const adminReducer = (state, action) => {
  switch (action.type) {
    case ADMIN_MEMBERLIST:
      return { ...state, memberList: action.memberList, total: action.total };
    case CLEAR_MEMBERSTORE:
      return {
        ...initState
      };
    default:
      return state;
  }
};

const useAdminReducer = () => useEnhanceReducer(adminReducer, initState);

export default useAdminReducer;
