import React, { useState, useMemo, useEffect, useCallback } from "react";
import styles from "./index.module.scss";
import { stringify } from 'query-string';
import useSetState from "customHooks/setState";
import moment from "moment";
import classnames from "classnames";
import Icon from "components/common/Icon";
import config from "config";
import { useStore, StoreTypes } from "context";
import { testState, getTokenToOneBook } from "repositories";
import { USER, TUTOR } from "constants/roleType";
import SweetAlert from 'react-bootstrap-sweetalert';
import { checkInTutorial, checkOutTutorial } from "repositories";
import { ALERT_MESSAGE_OPEN } from "constants/popupTypes";

const Tutorial = ({ role, data, enterState }) => {
  const HALFTIME = 1800000;
  const YET = "yet";
  const FINISH = "finish";
  const ENTER = "enter";
  const [{ token,info:MemberInfo,name:userName }] = useStore(StoreTypes.member);
  const [, dispatchPopupState] = useStore(StoreTypes.popup);
  const { APP_CLASS_DOMAIN, APP_ISDEV,APP_ONEBOARD_DOMAIN } = config;
  const {
    info,
    tutorNickname,
    startTime,
    endTime,
    id,
    bookId,
    tutorCheckInAt,
    tutorCheckOutAt,
    students
  } = data;
  const nowTime = Date.now();
  const canCheckoutByTime = nowTime > startTime - HALFTIME ? true : false;
  const [preState, setPreState] = useState(false);
  const [postState, setPostState] = useState(false);
  const [{
    alertState,
    alertType,
    chekoutButtonState,
    isFinishCheckout
  }, setState] = useSetState({
    alertState: false,
    alertType: 'checkIn',
    chekoutButtonState: tutorCheckInAt ? true : false,
    isFinishCheckout: tutorCheckOutAt ? true : false
  })
  const alertMessage = {
    checkIn: {
      text: `簽到成功，現在時間為${moment().format('YYYY-MM-DD HH:mm:ss')}`,
      confirm: async () => {
        await checkInTutorial({ tutorialId: id, token });
        setState({ alertState: false, chekoutButtonState: true });
      },
      cancel: () => {
        setState({ alertState: false });
      }
    },
    checkOut: {
      text: `現在時間為${moment().format('YYYY-MM-DD HH:mm:ss')}，您確定結束課程嗎?`,
      confirm: async () => {
        await checkOutTutorial({ tutorialId: id, token });
        window.open(`https://docs.google.com/forms/d/e/1FAIpQLScqdg675ccYb0nHZ9LnvdgX1nVOK5iRuFrVZO7NVxyrplEuQg/viewform`, "_blank");
        setState({ alertState: false, isFinishCheckout: true });
      },
      cancel: () => {
        setState({ alertState: false });
      }
    }
  }


  const isCanOverClass = () => {
    const nowTime = Date.now();
    if (nowTime < startTime) {
      dispatchPopupState({ type: ALERT_MESSAGE_OPEN, content: '目前還不能結束課程哦!!' });
      return;
    } else {
      changeAlert(true, 'checkOut');
    }
  }
  const router = useStore(StoreTypes.router);

  const enterStateStyle = {
    yet: "尚未開始",
    finish: "查看課程",
    enter: "進入教室"
  };
  const enterStateStyleHandler = () => {
    if (role === TUTOR) {
      return ENTER;
    }
    if (enterState === ENTER && startTime - nowTime > HALFTIME) {
      return YET;
    } else if (endTime < nowTime) {
      return FINISH;
    } else {
      return enterState;
    }
  };
  const dateFormat = useMemo(() => {
    const startOn = moment(startTime).format("YYYY/MM/DD HH:mm");
    const endOn = moment(endTime).format("HH:mm");
    const timeZone = new Date().toString().split('(')[1];
    return (`${startOn} ~ ${endOn} <span>(${timeZone}</span>`);
  }, [endTime, startTime]);

  const tutorialInfomationHandler = useMemo(() => {
    const educationType = {
      J: "國中",
      H: "高中",
      E: "國小"
    };
    const gradeType = {
      "1": "一",
      "2": "二",
      "3": "三",
      "4": "四",
      "5": "五",
      "6": "六"
    };
    return (
      educationType[info.educationName] +
      `${(gradeType[info.gradeName] || info.gradeName)}`
    );
  }, [info.educationName, info.gradeName]);

  const openTest = testId => {
    const openUrl = window.open("", "_blank");
    const { id, students, tutorNickname } = data;
    if (role === TUTOR) {
      const url = `http://tutor-exam.oneclass.com.tw/analysis.html?range=${testId}&user=${students[0].studentId}&roomId=${id}`;
      openUrl.location = url;
    } else {
      const url = `http://tutor-exam.oneclass.com.tw/index.html?range=${testId}&user=${students[0].studentId}&roomId=${id}&teacherName=${tutorNickname}&username=${students[0].studentNickname}`;
      openUrl.location = url;
    }
  };
  const openPreview = bookId => {
    window.open(`https://cdn.oneclass.com.tw/uploadoutput/${bookId}`, "_blank");
  };
  const halfTimeHandler = () => {
    if (enterState !== ENTER || startTime - nowTime > HALFTIME) {
      return role === TUTOR ? true : false;
    } else {
      return true;
    }
  };
  const enterClass = async () => {
    const status = enterStateStyleHandler();
    if (!token) return;
    const openUrl = window.open("", "tutor");
    const { code } = await getTokenToOneBook(token);
    const timestamp = moment().unix();
    let url = ''
    if(role === TUTOR){
      const querystring = stringify({
        bookIds: [bookId],
        classType: 'single',
        role: 'teacher',
        userName: userName,
        userId: MemberInfo.id
      },{ arrayFormat: 'index' });
      url = status === FINISH ? `${APP_CLASS_DOMAIN}/${bookId}?roomId=${id}&code=${code}&${timestamp}` : `${APP_ONEBOARD_DOMAIN}/${id}/setup?${querystring}`;
    }else{
      const memberId = students[0].studentId;
      const studentNickname = students[0].studentNickname
      const querystring = stringify({
        bookIds: [bookId],
        classType: 'single',
        role: 'student',
        userName: studentNickname,
        userId: memberId
      },{ arrayFormat: 'index' });
        await checkInTutorial({ tutorialId: id, token , memberId });
        url = status === FINISH ? `${APP_CLASS_DOMAIN}/${bookId}?roomId=${id}&studentId=${students[0].studentId}&code=${code}&${timestamp}` : `${APP_ONEBOARD_DOMAIN}/${id}/setup?${querystring}`;
    }
    openUrl.location = url;
  };

  const openAnswerPage = async testId => {
    if (!token || role !== TUTOR) return;
    const openUrl = window.open("", "tutor");
    const { code } = await getTokenToOneBook(token);
    const url = `http://tutor-exam.oneclass.com.tw/examination.html?range=${testId}&env=${APP_ISDEV ? 'dev' : 'release'}&code=${code}`
    openUrl.location = url;
  }

  const goReview = () => {
    const { tutorId, id, students, tutorNickname } = data;
    const params = {
      reviewer: role === TUTOR ? tutorId : students[0].studentId,
      reviewerName: role === TUTOR ? students[0].studentNickname : tutorNickname,
      reviewee: role === TUTOR ? students[0].studentId : tutorId,
      tutorialIds: [id]
    };
    router.history.push({ pathname: `/Review`, state: params });
  };
  const getTestState = useCallback(async () => {
    const { students, preExamId, postExamId, id } = data;
    const beforeClass = await testState({
      userId: students[0].studentId,
      examId: preExamId,
      roomId: id
    });
    const afterClass = await testState({
      userId: students[0].studentId,
      examId: postExamId,
      roomId: id
    });
    setPreState(beforeClass);
    setPostState(afterClass);
  });

  const changeAlert = (state, type) => {
    setState({
      alertState: state,
      alertType: type
    })
  }
  useEffect(() => {
    setPreState(data.beforeClass);
    setPostState(data.afterClass);
  }, [data]);

  useEffect(() => {
    getTestState()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className={styles.main}>
      <div className={classnames(styles.top, styles[enterState])}>
        <div className={styles.info}>
          {
            tutorialInfomationHandler !== 'undefined' ? (
              <>
                <span>{tutorialInfomationHandler}年級</span>
                <span>|</span>
              </>
            ) : ''
          }
          <span>{role === USER ? `${tutorNickname} 老師` : students[0].studentNickname}</span>
          <span>|</span>
          <span>{info.subjectName}</span>
          <span>|</span>
          <span>{info.className}</span>
        </div>
        <div className={styles.date} dangerouslySetInnerHTML={{ __html: dateFormat }}></div>
        <div className={styles.enterClass}>
          {
            <div
              className={styles[enterStateStyleHandler()]}
              // eslint-disable-next-line react/jsx-no-target-blank
              onClick={() => enterClass()}
            >
              {enterStateStyle[enterStateStyleHandler()]}
            </div>
          }
        </div>
      </div>
      <div className={styles.bottom}>
        <span
          onClick={() => {
            getTestState();
          }}
        >
          <Icon name="sync" />
        </span>
        {
          (canCheckoutByTime && role === TUTOR && halfTimeHandler()) &&
          <div className={styles[chekoutButtonState ? 'CheckInFinish' : 'checkIn']}
            onClick={() => {
              if (chekoutButtonState) return;
              changeAlert(true, 'checkIn')
            }}>
            {chekoutButtonState ? '簽到完成' : '教師簽到'}
          </div>
        }
        {
          (role === TUTOR && halfTimeHandler() && chekoutButtonState && !isFinishCheckout) &&
          <div
            className={styles['checkOut']}
            onClick={() => isCanOverClass()}>
            結束課程
          </div>
        }
        <div
          className={styles.preview}
          onClick={() => {
            openPreview(data.bookId);
          }}
        >
          教材預覽
        </div>
        {data.preExamId && <div
          className={styles[preState ? "before_finish" : "before_yet"]}
          onClick={() => {
            openTest(data.preExamId);
          }}
        >
          {preState ? "課前結果" : "課前測驗"}
        </div>}
        {(data.preExamId && role === TUTOR) && <div
          className={styles.before_finish}
          onClick={() => {
            openAnswerPage(data.preExamId);
          }}
        >
          前測解答
        </div>}
        {data.postExamId && <div
          className={styles[postState ? "after_finish" : "after_yet"]}
          onClick={() => {
            openTest(data.postExamId);
          }}
        >
          {postState ? "課後結果" : "課後測驗"}
        </div>}
        {(data.postExamId && role === TUTOR) && <div
          className={styles.after_finish}
          onClick={() => {
            openAnswerPage(data.postExamId);
          }}
        >
          後測解答
        </div>}
      </div>
      <SweetAlert
        warning
        show={alertState}
        customButtons={
          <>
            <div className={styles.confirm} onClick={() => alertMessage[alertType].confirm()}>確定</div>
            <div className={styles.cancel} onClick={() => alertMessage[alertType].cancel()}>取消</div>
          </>
        }
      >{alertMessage[alertType].text}</SweetAlert>
    </div>
  );
};

export default Tutorial;
