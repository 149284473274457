import React, { useState, useEffect, useCallback } from "react";
import styles from "./index.module.scss";
import preclude1 from "static/images/answer/preclude-1.png";
import preclude2 from "static/images/answer/preclude-2.png";
import preclude3 from "static/images/answer/preclude-3.png";
import preclude4 from "static/images/answer/preclude-4.png";
import preclude5 from "static/images/answer/preclude-5.png";
import preclude6 from "static/images/answer/preclude-6.png";
import teamview from "static/images/answer/teamview.png";
import useCheckDevice from "customHooks/checkDevice";
import Icon from "components/common/Icon";
import Button from "components/common/Button";
import { useStore, StoreTypes } from "context";
import { ALERT_MESSAGE_OPEN } from "constants/popupTypes";

const Answer = () => {
  const [, dispatchPopupState] = useStore(StoreTypes.popup);
  const router = useStore(StoreTypes.router);
  const [tab, setTab] = useState("checkDevice");
  const [
    checkDeviceHandler,
    checkbowersHandler,
    systemState,
    deviceState,
    openWebCam,
    openAudio,
    audioBar
  ] = useCheckDevice();
  const { browser, system } = systemState;
  const { hasWebcam, hasMic, micName, webCamName } = deviceState;
  const [videoState, setVideoState] = useState(false);
  const [audioState, setAudioState] = useState(false);
  const template = {
    webCam: [
      {
        narrative: `請先點選開啟<a href="https://www.vidyard.com/cam-test" target='_blank'>測試網頁</a>。`,
        image: preclude1
      },
      {
        narrative: `點選"Grant Mic Access"。`,
        image: ""
      },
      {
        narrative: `跳出要求使用相機權限確認視窗、請點選允許。`,
        image: preclude2
      },
      {
        narrative: `接著瀏覽器左上方會出現"相機已開啟"訊息，此時如果畫面中出現您自己的影像，即為您的網路攝影機已開啟並正常使用，即可關閉次網頁並回到OneClass家教。`,
        image: preclude3
      },
      {
        narrative: `如未看到自己的影像，請參考[<a href='https://support.microsoft.com/zh-tw/help/4028647/windows-open-the-camera-in-windows-10' target='_blank'>開啟 Windows 10 中的相機</a>]，開啟網路攝影機`
      }
    ],
    mic: [
      {
        narrative: `請先點選開啟<a href="https://www.vidyard.com/mic-test/" target="_blank">測試網頁</a>。`
      },
      {
        narrative: `點選"Grant Mic Access"。`,
        image: preclude4
      },
      {
        narrative: `跳出要求使用麥克風權限確認視窗、請點選"允許"。`,
        image: preclude5
      },
      {
        narrative: `如果畫面中有出現類似上圖的聲波曲線、代表麥克風可正常收音。`,
        image: preclude6
      },
      {
        narrative: `麥克風如果無法正常收音，或是安裝新的麥克風，請參考[<a href="https://support.microsoft.com/zh-tw/help/4027981/windows-10-how-to-set-up-and-test-microphones" target="_blank">如何在 Windows 10 中設定和測試麥克風</a>]。`
      }
    ],
    teamViewer: [
      {
        narrative: `如有其他無法解決的系統問題，請下載。`,
        image: teamview,
        imageLink: "https://get.teamviewer.com/nanidigi"
      },
      {
        narrative: `並聯絡輔導老師，將會有專人協助您排除問題。`
      }
    ]
  };
  const tabText = {
    webCam: "網路攝影機測試",
    mic: "麥克風測試",
    teamViewer: "遠端連線",
    checkDevice: "檢查設備"
  };
  const [answerTemplate, setAnswerTemplate] = useState(template.checkDevice);
  const answerTemplateHandler = value => {
    setTab(value);
    setVideoState(false);
    setAnswerTemplate(template[value]);
  };
  const openWebCamHandler = useCallback(() => {
    if (!hasWebcam) {
      dispatchPopupState({
        type: ALERT_MESSAGE_OPEN,
        content: "您沒有網路攝影機or沒有給予開啟的權限!"
      });
      return;
    }
    openWebCam();
    setVideoState(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasWebcam]);
  const openAudioHandler = useCallback(() => {
    if (!hasMic) {
      dispatchPopupState({
        type: ALERT_MESSAGE_OPEN,
        content: "您沒有麥克風or沒有給予開啟的權限!"
      });
      return;
    }
    openAudio();
    setAudioState(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasMic]);
  useEffect(() => {
    checkDeviceHandler();
    checkbowersHandler();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [videoState]);
  return (
    <section className={styles.answer}>
      <div className="container">
        <h3 className={styles.answer__title}>障礙排除</h3>
        <ul className={styles.answer__tab}>
          <li
            key="checkDevice"
            className={tab === "checkDevice" ? styles.active : ""}
            onClick={() => answerTemplateHandler("checkDevice")}
          >
            {tabText["checkDevice"]}
          </li>
          {Object.keys(template).map(item => (
            <li
              key={item}
              className={tab === item ? styles.active : ""}
              onClick={() => answerTemplateHandler(item)}
            >
              {tabText[item]}
            </li>
          ))}
        </ul>
        <div className={styles.answer__buttonBox}>
          <Button type="goback" onClick={() => router.history.goBack()}>返回上一頁</Button>
        </div>
        <div className={styles.answer__content}>
          {answerTemplate &&
            answerTemplate.map((item, index) => {
              return (
                <div className={styles.answer__contentItem} key={index}>
                  <h5
                    className={styles.answer__narrative}
                    dangerouslySetInnerHTML={{ __html: item.narrative }}
                  ></h5>
                  {item.image &&
                    (item.imageLink ? (
                      <a
                        className={styles.answer__image}
                        href={item.imageLink}
                        // eslint-disable-next-line react/jsx-no-target-blank
                        target="_blank"
                      >
                        <img src={item.image} alt={item.narrative} />
                      </a>
                    ) : (
                        <div className={styles.answer__image}>
                          <img src={item.image} alt={item.narrative} />
                        </div>
                      ))}
                </div>
              );
            })}
          {tab === "checkDevice" && (
            <div className={styles.answer__contentItem}>
              <h5 className={styles.answer__narrative}>
                檢查作業系統版本&瀏覽器版本 :
              </h5>
              <div className={styles.answer__ans}>作業系統版本 : {system}</div>
              <div className={styles.answer__ans}>瀏覽器版本 : {browser}</div>
              <h5 className={styles.answer__narrative}>
                檢查網路攝影機&麥克風 :
              </h5>
              <div className={styles.answer__ans}>
                網路攝影機 :
                <Icon
                  className={
                    styles[
                    hasWebcam
                      ? "answer__ansState_success"
                      : "answer__ansState_fail"
                    ]
                  }
                  name={hasWebcam ? "circle" : "timesCircle"}
                />
                {webCamName}
              </div>
              <div className={styles.answer__ans}>
                麥克風 :
                <Icon
                  className={
                    styles[
                    hasMic
                      ? "answer__ansState_success"
                      : "answer__ansState_fail"
                    ]
                  }
                  name={hasMic ? "circle" : "timesCircle"}
                />
                {micName}
              </div>
              <h5 className={styles.answer__narrative}>開啟網路攝影機 :</h5>
              <div
                className={styles.answer__videoBox}
                onClick={() => {
                  videoState || openWebCamHandler();
                }}
              >
                <Icon
                  name="video"
                  className={videoState ? styles.answer__videoIcon : ""}
                />
                <span className={videoState ? styles.answer__videoText : ""}>
                  點擊測試網路攝影機
                </span>
                <video
                  autoPlay
                  id="videoElement"
                  className={styles.videoElement}
                ></video>
              </div>

              <h5 className={styles.answer__narrative}>測試麥克風音量 :</h5>
              <ul className={styles.answer__step}>
                <li>a. 點擊按鈕開啟麥克風。</li>
                <li>b. 盡情的吶喊!</li>
                <li>c. 看到測試條有波動，您的麥克風是正常的!</li>
              </ul>
              {audioState ? (
                <div className={styles.answer__audioBar}>
                  <span style={{ width: audioBar + "%" }}></span>
                </div>
              ) : (
                  <div
                    onClick={() => {
                      openAudioHandler();
                    }}
                    className={styles.answer__micBox}
                  >
                    <Icon name="mic" />
                  開始麥克風測試
                  </div>
                )}
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default Answer;
