import React, { useEffect, useState } from "react";
import { getFamilyPofile } from "repositories";
import { useStore, StoreTypes } from "context";
import { GET_MEMBER } from "constants/memberType";
import Home from "pages/home";
import styles from "./index.module.scss";
import MemberCard from "components/memberCard";
import Icon from "components/common/Icon";
import { ADDMEMBER_POPUP, LODING_POPUP } from "constants/popupTypes";

const MemberFamily = () => {
  const [{ popupState }, dispatchPopupState] = useStore(StoreTypes.popup);
  const [, dispatchClassShedule] = useStore(StoreTypes.classSchedule);
  const [{ token }] = useStore(StoreTypes.member);
  const [{ members }, membersDispatch] = useStore(StoreTypes.member);
  const router = useStore(StoreTypes.router);
  const [editId, setEditId] = useState("");
  useEffect(() => {
    (async () => {
      if (!token || members.length > 0) return;
      await dispatchPopupState({ type: LODING_POPUP, lodingState: true });
      const results = await getFamilyPofile({ token });
      if (results) {
        results.members.length === 0 &&
          dispatchPopupState({ type: ADDMEMBER_POPUP, popupState: false });
      } else {
        router.history.push({ pathname: `/` });
      }
      await dispatchPopupState({ type: LODING_POPUP, lodingState: false });
      results &&
        membersDispatch({ type: GET_MEMBER, members: results.members });
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.history, token]);
  const openClass = item => {
    dispatchClassShedule({
      type: "GET_STUDENT",
      Student: {
        Id: item.id,
        Name: item.name ? item.name : item.nickname
      }
    });
    router.history.push(`/EditCourse/${item.id}`);
    // router.history.push({ pathname: `/EditCourse/${item.id}`,state:{} });
  };
  const editUser = async index => {
    await setEditId(index);
    await dispatchPopupState({ type: ADDMEMBER_POPUP, popupState: true });
  };
  const closeHandler = () => {
    setEditId("");
    dispatchPopupState({
      type: ADDMEMBER_POPUP,
      popupState: false
    });
  };
  return (
    <section className={styles.family}>
      <div className="container">
        <div className={styles.family__content}>
          {members.length > 0 &&
            members.map((item, index) => {
              return (
                <MemberCard
                  name={item.name ? item.name : item.nickname}
                  gender={item.gender}
                  onClick={() => {
                    openClass(item);
                  }}
                  edit={() => editUser(index)}
                  key={`${item.id}`}
                />
              );
            })}
          <div
            className={styles.family__button}
            onClick={() => {
              dispatchPopupState({ type: ADDMEMBER_POPUP, popupState: true });
            }}
          >
            <div className={styles.family__buttonBackground}>
              <Icon name={"plus"} />
            </div>
            <div className={styles.family__buttonName}>新增使用者</div>
          </div>
        </div>
      </div>
      {popupState && (
        <div className={styles.family__popup}>
          <div className={styles.family__popupContent}>
            <div
              className={styles.family__popupClose}
              onClick={() => {
                closeHandler();
              }}
            >
              X
            </div>
            <Home isFamily={true} editId={editId} />
          </div>
        </div>
      )}
    </section>
  );
};

export default MemberFamily;
