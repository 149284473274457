import { useEnhanceReducer } from "customHooks/enhanceReducer";
// import * as types from 'constants/actionTypes';
// import { Roles } from 'constants/role';

export const initState = {
  userId: "",
  name: "",
  isLogin: false,
  role: "guest",
  token: null,
  members: [],
  info: []
};

const updateUserInfo = (state, action) => {
  const {
    userId = state.userId,
    name = state.name,
    role = state.role,
    isLogin = state.isLogin,
    token = state.token,
    info = {
      address: state.info.address,
      birthday: state.info.birthday,
      firstName: state.info.firstName,
      gender: state.info.gender,
      id: state.info.id,
      lastName: state.info.lastName,
      mobileNumber: state.info.mobileNumber,
      nickname: state.info.nickname,
      phoneNumber: state.info.phoneNumber,
      role: state.info.role,
      email: state.info.email
    }
  } = action;
  return { ...state, userId, name, role, isLogin, token, info };
};

const userReducer = (state, action) => {
  switch (action.type) {
    case "UPDATE_USER_INFO":
      return updateUserInfo(state, action);
    case "RESET_USER_INFO":
      return { ...initState };
    case "GET_MEMBER":
      return { ...state, members: action.members };
    default:
      return state;
  }
};

const useUserReducer = () => useEnhanceReducer(userReducer, initState);

export default useUserReducer;
