import { PreparationAPI } from "api";
import { stringify } from 'query-string';
import config from "config";
import { USER } from "constants/roleType";
const {
  APP_ONETUTOR_DOMAIN,
  APP_ONECLASS_DOMAIN,
  APP_USER_VALID_DOMAIN,
  APP_TOKEN_DOMAIN
} = config;

const SUCCESS = "success";

export const createUserProfile = async ({ params, token }) => {
  try {
    const headers = {
      Authorization: token
    };
    const response = await PreparationAPI.post(
      `${APP_ONETUTOR_DOMAIN}/users`,
      headers,
      params
    );
    if (response.status === SUCCESS) {
      return response.data;
    } else {
      return response.error;
    }
  } catch (err) {
    console.log(`post /createUserProfile error ${err}`);
  }
  return [];
};
export const updateUserProfile = async ({ params, token }) => {
  try {
    const headers = {
      Authorization: token
    };
    const response = await PreparationAPI.put(
      `${APP_ONETUTOR_DOMAIN}/users`,
      headers,
      params
    );
    if (response.status === SUCCESS) {
      return response.data;
    } else {
      return response.error;
    }
  } catch (err) {
    console.log(`post /updateUserProfile error ${err}`);
  }
  return [];
};
export const getMe = async ({ token }) => {
  try {
    const headers = {
      Authorization: token
    };
    const response = await PreparationAPI.get(
      `${APP_ONETUTOR_DOMAIN}/users/me`,
      headers
    );
    if (response.status === SUCCESS) {
      return response.data;
    } else {
      return response.error;
    }
  } catch (err) {
    console.log(`get /getMe error ${err}`);
  }
};
export const getFamilyPofile = async ({ token }) => {
  try {
    const headers = {
      Authorization: token
    };
    const response = await PreparationAPI.get(
      `${APP_ONETUTOR_DOMAIN}/users/members`,
      headers
    );
    if (response.status === SUCCESS) {
      return response.data;
    }
  } catch (err) {
    console.log(`get /getFamilyPofile error ${err}`);
  }
};

export const getTutorials = async ({ token, role, memberId, startAfter, limit=10,skip=0 }) => {
  try {
    const headers = {
      Authorization: token
    };
    const queryParams = {
      startAfter,
      limit,
      skip:skip * limit
    };
    if (role === USER) queryParams.memberId = memberId;
    const querystring = stringify(queryParams);


    const _url = `${APP_ONETUTOR_DOMAIN}/tutorials/mine?${querystring}`;
    const response = await PreparationAPI.get(_url, headers);
    if (response.status === SUCCESS) {
      return response.data;
    } else {
      return response.error;
    }
  } catch (err) {
    console.log(`get /getTutirials error ${err}`);
  }
};
export const getSingleTutorial = async ({ token, params }) => {
  try {
    const headers = {
      Authorization: token
    };

    const response = await PreparationAPI.get(
      `${APP_ONETUTOR_DOMAIN}/tutorials/${params.tutorialId}?tutorId=${params.tutorId}&studentId=${params.studentId}`,
      headers
    );

    if (response.status === SUCCESS) {
      return response.data;
    } else {
      return response.error;
    }
  } catch (err) {
    console.log(`post /getSingleTutorial error ${err}`);
  }
};
export const createTutorial = async ({ token, params }) => {
  try {
    const headers = {
      Authorization: token
    };
    const response = await PreparationAPI.post(
      `${APP_ONETUTOR_DOMAIN}/admin/tutorials`,
      headers,
      params
    );
    if (response.status === SUCCESS) {
      return response.data;
    } else {
      return response.error;
    }
  } catch (err) {
    console.log(`post /createTutorial error ${err}`);
  }
};
export const updateTutorial = async ({ token, tutorialId, params }) => {
  try {
    const headers = {
      Authorization: token
    };
    const response = await PreparationAPI.put(
      `${APP_ONETUTOR_DOMAIN}/admin/tutorials/${tutorialId}`,
      headers,
      params
    );
    if (response.status === SUCCESS) {
      return response.data;
    } else {
      return response.error;
    }
  } catch (err) {
    console.log(`post /updateTutorial error ${err}`);
  }
};
export const deleteTutorial = async ({ token, tutorialId }) => {
  try {
    const headers = {
      Authorization: token
    };
    const response = await PreparationAPI.delete(
      `${APP_ONETUTOR_DOMAIN}/admin/tutorials/${tutorialId}`,
      headers
    );
    if (response.status === SUCCESS) {
      return response.status;
    } else {
      return response.error;
    }
  } catch (err) {
    console.log(`post /updateTutorial error ${err}`);
  }
};
export const getUser = async ({ token, params = {} }) => {
  try {
    const header = {
      Authorization: token
    };
    const response = await PreparationAPI.get(
      `${APP_ONETUTOR_DOMAIN}/admin/users`,
      header,
      params
    );
    if (response.status === SUCCESS) {
      return response.data;
    } else {
      return response.error;
    }
  } catch (error) {
    console.log(`get /admin/users error ${error}`);
  }
};

export const getUserPublicProfile = async ({ token, userId }) => {
  try {
    const header = {
      Authorization: token
    };
    const response = await PreparationAPI.get(
      `${APP_ONETUTOR_DOMAIN}/users/${userId}/profile`,
      header
    );
    if (response.status === SUCCESS) {
      return response.data;
    } else {
      return response.error;
    }
  } catch (error) {
    console.log(`get /getUserPublicProfile error ${error}`);
  }
};
export const getMember = async ({ id, token }) => {
  try {
    const header = {
      Authorization: token
    };

    const response = await PreparationAPI.get(
      `${APP_ONETUTOR_DOMAIN}/users/members/${id}`,
      header
    );

    if (response.status === SUCCESS) {
      return response.data;
    } else {
      return response.error;
    }
  } catch (error) {
    console.log(`get /getMember error ${error}`);
  }
};
export const getUserProfile = async ({ id, token }) => {
  try {
    const header = {
      Authorization: token
    };

    const response = await PreparationAPI.get(
      `${APP_ONETUTOR_DOMAIN}/users/members/${id}/profile`,
      header
    );

    if (response.status === SUCCESS) {
      return response.data;
    } else {
      return response.error;
    }
  } catch (error) {
    console.log(`get /getUserProfile error ${error}`);
  }
};
export const addUserFamily = async ({ params, token }) => {
  try {
    const headers = {
      Authorization: token
    };
    const response = await PreparationAPI.post(
      `${APP_ONETUTOR_DOMAIN}/users/members`,
      headers,
      params
    );
    if (response.status === SUCCESS) {
      return response.data;
    } else {
      return response.error;
    }
  } catch (err) {
    console.log(`post /createUserProfile error ${err}`);
  }
  return [];
};
export const updateUserMember = async ({ params, token, userMemberId }) => {
  try {
    const headers = {
      Authorization: token
    };
    const response = await PreparationAPI.put(
      `${APP_ONETUTOR_DOMAIN}/users/members/${userMemberId}`,
      headers,
      params
    );
    if (response.status === SUCCESS) {
      return response.data;
    } else {
      return response.error;
    }
  } catch (err) {
    console.log(`post /updateUserMember error ${err}`);
  }
  return [];
};
export const getTutorialsAdmin = async ({ params, token }) => {
  try {
    const headers = {
      Authorization: token
    };
    const response = await PreparationAPI.get(
      `${APP_ONETUTOR_DOMAIN}/admin/tutorials?limit=${params.limit}&startTime=${params.startTime
      }&endTime=${params.endTime}${params.tutorPhoneNumber
        ? `&tutorPhoneNumber=${params.tutorPhoneNumber}`
        : ""
      }${params.studentPhoneNumber
        ? `&studentPhoneNumber=${params.studentPhoneNumber}`
        : ""
      }`,
      headers,
      params
    );
    if (response.status === SUCCESS) {
      return response.data;
    } else {
      return response.error;
    }
  } catch (err) {
    console.log(`post /getTutorialsAdmin error ${err}`);
  }
};

export const getTutorialsAdminById = async ({ id, role, token }) => {
  try {
    const headers = {
      Authorization: token
    };
    const apiUrl =
      role === USER
        ? `${APP_ONETUTOR_DOMAIN}/admin/tutorials?studentId=${id}`
        : `${APP_ONETUTOR_DOMAIN}/admin/tutorials?tutorId=${id}`;
    const response = await PreparationAPI.get(apiUrl, headers);
    if (response.status === SUCCESS) {
      return response.data;
    } else {
      return response.error;
    }
  } catch (err) {
    console.log(`post /getTutorialsAdmin error ${err}`);
  }
};

export const createReview = async ({ params, token }) => {
  try {
    const headers = {
      Authorization: token
    };
    const response = await PreparationAPI.post(
      `${APP_ONETUTOR_DOMAIN}/reviews`,
      headers,
      params
    );
    if (response.status === SUCCESS) {
      return response.data;
    } else {
      return response.error;
    }
  } catch (err) {
    console.log(`post /createReview error ${err}`);
  }
};

export const testState = async params => {
  try {
    const url = "https://exam-test-671e2.firebaseapp.com/OnGetExam2";
    const headers = {
      "Content-Type": "application/x-www-form-urlencoded"
    };
    const response = await PreparationAPI.formPost(url, headers, params);
    if (response.status === SUCCESS) {
      if (response.content !== "") {
        return true;
      } else {
        return false;
      }
    } else {
      return response.error;
    }
  } catch (err) {
    console.log(`post /testState error ${err}`);
  }
};

export const adminUpdateUserProfile = async ({
  params,
  token,
  userMemberId
}) => {
  try {
    const headers = {
      Authorization: token
    };
    const response = await PreparationAPI.put(
      `${APP_ONETUTOR_DOMAIN}/admin/users/${userMemberId}`,
      headers,
      params
    );
    if (response.status === SUCCESS) {
      return response.data;
    } else {
      return response.error;
    }
  } catch (err) {
    console.log(`post /adminUpdateUserProfile error ${err}`);
  }
  return [];
};

export const adminUserMemberProfile = async ({
  params,
  token,
  userMemberId
}) => {
  try {
    const headers = {
      Authorization: token
    };
    const response = await PreparationAPI.put(
      `${APP_ONETUTOR_DOMAIN}/admin/users/members/${userMemberId}`,
      headers,
      params
    );
    if (response.status === SUCCESS) {
      return response.data;
    } else {
      return response.error;
    }
  } catch (err) {
    console.log(`post /adminUserMemberProfile error ${err}`);
  }
  return [];
};

export const getUserById = async ({ userId, token }) => {
  try {
    const headers = {
      Authorization: token
    };
    const response = await PreparationAPI.get(
      `${APP_ONETUTOR_DOMAIN}/admin/users/${userId}`,
      headers,
    );
    if (response.status === SUCCESS) {
      return response.data;
    } else {
      return response.error;
    }
  } catch (err) {
    console.log(`post /adminUserMemberProfile error ${err}`);
  }
}

export const getMemo = async ({ userId, token }) => {
  try {
    const headers = {
      Authorization: token
    };
    const response = await PreparationAPI.get(
      `${APP_ONETUTOR_DOMAIN}/admin/memos/${userId}`,
      headers,
    );
    if (response.status === SUCCESS) {
      return response.data;
    } else {
      return response.error;
    }
  } catch (err) {
    console.log(`post /getMemo error ${err}`);
  }
}

export const updateMemo = async ({ payload, userId, token }) => {
  try {
    const headers = {
      Authorization: token
    };
    const queryParams = {};
    if (payload.memberId) {
      queryParams.memberId = payload.memberId;
    }
    const querystring = stringify(queryParams);

    const response = await PreparationAPI.put(
      `${APP_ONETUTOR_DOMAIN}/admin/memos/${userId}?${querystring}`,
      headers,
      payload
    );
    if (response.status === SUCCESS) {
      return response.data;
    } else {
      return response.error;
    }
  } catch (err) {
    console.log(`post /updateMemo error ${err}`);
  }
}

//電話註冊
export const phoneRegister = async params => {
  const headers = {};
  try {
    const response = await PreparationAPI.clubPost(
      `${APP_ONECLASS_DOMAIN}/signupMobile`,
      headers,
      params
    );
    return response;
  } catch (err) {
    console.log(`post /phoneRegister error ${err}`);
  }
};

//檢查帳號是否存在
export const accountIsExsit = async params => {
  try {
    const response = await PreparationAPI.postText(
      `${APP_USER_VALID_DOMAIN}/userExist`,
      params
    );
    return response;
  } catch (err) {
    console.log(`post /accountIsExsit error ${err}`);
  }
};

//檢查簡訊驗證碼
export const checkMobileValid = async params => {
  try {
    const headers = {};
    const response = await PreparationAPI.post(
      `${APP_ONECLASS_DOMAIN}/checkMobileValid`,
      headers,
      params
    );

    if (response.code === "SUCCESS") {
      return response;
    } else {
      return response.Error;
    }
  } catch (err) {
    console.log(`post /accountIsExsit error ${err}`);
  }
};

//建立帳號
export const createMobileAccount = async params => {
  try {
    const headers = {};
    const response = await PreparationAPI.post(
      `${APP_ONECLASS_DOMAIN}/createMobileAccount`,
      headers,
      params
    );
    if (response.code === "SUCCESS") {
      return response;
    } else {
      return response.Error;
    }
  } catch (err) {
    console.log(`post /createMobileAccount error ${err}`);
  }
};

//忘記密碼
export const forgetPassword = async params => {
  const headers = {};
  try {
    const response = await PreparationAPI.post(
      `${APP_ONECLASS_DOMAIN}/forgetMobilePassword`,
      headers,
      params
    );
    if (response.code === "SUCCESS") {
      return response;
    } else {
      return response.Error;
    }
  } catch (err) {
    console.log(`post /forgetPassword error ${err}`);
  }
};

//忘記密碼檢查簡訊驗證碼
export const passwordCheckMobileValid = async params => {
  try {
    const headers = {};
    const response = await PreparationAPI.post(
      `${APP_ONECLASS_DOMAIN}/checkForgetPasswordMobileValid`,
      headers,
      params
    );

    if (response.code === "SUCCESS") {
      return response;
    } else {
      return response.Error;
    }
  } catch (err) {
    console.log(`post /passwordCheckMobileValid error ${err}`);
  }
};

//修改密碼
export const revisePassword = async params => {
  try {
    const headers = {};
    const response = await PreparationAPI.post(
      `${APP_ONECLASS_DOMAIN}/resetMobilePassword`,
      headers,
      params
    );
    if (response.code === "SUCCESS") {
      return response;
    } else {
      return response.Error;
    }
  } catch (err) {
    console.log(`post /revisePassword error ${err}`);
  }
};


export const adminCreateUser = async ({ params, token }) => {
  const header = {
    Authorization: token
  };
  const response = await PreparationAPI.post(
    `${APP_ONECLASS_DOMAIN}/signUpMobileAdmin `,
    header,
    params
  );
  return response;
}

export const emailToOneClass = async ({ params, token }) => {
  try {
    const header = {
      Authorization: token
    };
    const response = await PreparationAPI.post(
      `https://9bvi38het2.execute-api.ap-northeast-1.amazonaws.com/dev/members/updateEmailOnlyByMobile`,
      header,
      params
    );
    if (response.code === SUCCESS) {
      return response;
    } else {
      return response.error;
    }
  } catch (err) {
    console.log(`post /emailToOneClass error ${err}`);
  }
}

//老師/學生 課程簽到
export const checkInTutorial = async ({ tutorialId, token,memberId }) => {
  try {
    const headers = {
      Authorization: token
    };
    
    let queryParams = {};

    if(memberId){
      queryParams.memberId = memberId;
    }

    const querystring = stringify(queryParams);

    const response = await PreparationAPI.post(
      `${APP_ONETUTOR_DOMAIN}/tutorials/${tutorialId}/check-in?${querystring}`,
      headers,
    );
    if (response.status === SUCCESS) {
      return response.data;
    } else {
      return response.error;
    }
  } catch (err) {
    console.log(`post /checkInTutorial error ${err}`);
  }
}

//老師下課
export const checkOutTutorial = async ({ tutorialId, token }) => {
  try {
    const header = {
      Authorization: token
    };
    const response = await PreparationAPI.post(
      `${APP_ONETUTOR_DOMAIN}/tutorials/${tutorialId}/check-out`,
      header,
    );
    if (response.status === SUCCESS) {
      return response.data;
    } else {
      return response.error;
    }
  } catch (err) {
    console.log(`post /checkOutTutorial error ${err}`);
  }
}

export const getTokenToOneBook = async token => {
  try {
    const header = {
      Authorization: token
    };
    const response = await PreparationAPI.post(
      `${APP_TOKEN_DOMAIN}/members/createOneClassLink`,
      header,
    );
    if (response.status === SUCCESS) {
      return response.data;
    } else {
      return response.error;
    }
  } catch (err) {

  }
}



//錄影

export const startTutorialRecording = async ({ token, tutorialId }) => {
  try {
    const headers = {
      Authorization: token
    };

    const response = await PreparationAPI.post(
      `${APP_ONETUTOR_DOMAIN}/admin/tutorials/${tutorialId}/recording?action=start`,
      headers
    );
    if (response.status === SUCCESS) {
      return response.data;
    } else {
      return response.error;
    }
  } catch (err) {
    console.log(`post /startTutorialRecording error ${err}`);
  }
};

export const stopTutorialRecording = async ({ token, tutorialId }) => {
  try {
    const headers = {
      Authorization: token
    };

    const response = await PreparationAPI.post(
      `${APP_ONETUTOR_DOMAIN}/admin/tutorials/${tutorialId}/recording?action=stop`,
      headers
    );
    if (response.status === SUCCESS) {
      return response.data;
    } else {
      return response.error;
    }
  } catch (err) {
    console.log(`post /startTutorialRecording error ${err}`);
  }
};


export const getOneTeacherCode = async ({ token }) => {
  try {
    const headers = {
      Authorization: token,
    };

    const url = 'https://zib0bugold.execute-api.ap-northeast-1.amazonaws.com/prod/users/createShortCode';

    const response = await PreparationAPI.post(url,headers);

    if (response.status === SUCCESS) {
      return response.data;
    } else {
      return response.error;
    }
  } catch (err) {
    console.log(`post createShortCode error ${err}`);
  }
}

export const getEduSubject = async () => {
  try {

    const url = 'https://resource-library-api.oneclass.com.tw/api/Open/Selection/EduSubject';

    const response = await PreparationAPI.get(url);
    return response;
  } catch (err) {
    console.log(`getEduSubject error ${err}`);
  }
}

export const getTutorialsEduSubject = async ({ tutorialId, token }) => {
  try {

    const headers = {
      Authorization: token
    };

    let queryParams = {};

    if(tutorialId){
      queryParams.tutorialId = tutorialId;
    }

    const querystring = stringify(queryParams);

    const url = `${APP_ONETUTOR_DOMAIN}/admin/edu-subjects?${querystring}`;
    const response = await PreparationAPI.get(url, headers);

    if (response.status === SUCCESS) {
      return response.data;
    } else {
      return response.error;
    }

  } catch (err) {
    console.log(`getTutorialsEduSubject error ${err}`);
  }
}
